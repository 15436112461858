import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid"
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_PAGINGRESULT_FULFILLED = "FETCH_PAGINGRESULT_FULFILLED";
export const FETCH_PAGINGRESULT_REJECTED = "FETCH_PAGINGRESULT_REJECTED";
export const FETCH_PAGINGRESULT_PENDING = "FETCH_PAGINGRESULT_PENDING";
export const RESET_PAGINGRESULT = "RESET_PAGINGRESULT";

export function pagingResult(pageSize,pageNumber,culture,characterType) {
    const accessToken = localStorage.getItem('accessToken');
    return (dispatch) => {
        dispatch({
            type: "FETCH_PAGINGRESULT",
            payload: axios({
                method: HttpMethod.GET,
                url: `${API_BASE}/api/v2/admin/paging-character-test-result?pageSize=${pageSize}&pageNumber=${pageNumber}&culture=${culture}`,
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    "Client-Id": createuuid(),
                    "ngrok-skip-browser-warning": "69920"
                },
            }).then((result) => result.data)
        });
    };
}

export function resetPagingResult() {
    return (dispatch) => {
        dispatch({
            type: "RESET_PAGINGRESULT",
        });
    };
  }




