import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import Header from '../layout/Header';
import { useNavigate, useParams } from 'react-router';
import SectionCard from './SectionCard';
import { getContentByType } from '../../actions/content/getContentByType';
import { getLectureDetail, resetGetLectureDetail } from '../../actions/lecture/getLectureDetail';
import Select from 'react-select'
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { resetUpdateLecture, updateLecture } from '../../actions/lecture/updateLecture';
import {deleteLecture, resetDeleteLecture} from '../../actions/lecture/deleteLecture';

function LectureDetail(props) {
    const { Id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [displayOrder, setDisplayOrder] = useState(0);
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [sselectedOptions, ssetSelectedOptions] = useState([]);
    const [addingPeerContentList, setAddingPeerContentList] = useState();
    const [options, setOptions] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [removingSections, setRemovingSections] = useState([]);
    const [addingSections, setAddingSections] = useState([]);
    const [sections, setSections] = useState([
        {
            "Id": "00000000-0000-0000-0000-000000000000",
            "DisplayOrder": 0,
            "Title": null,
            "SubTitle": null
        }
    ]);
    useEffect(() => {
        dispatch(getLectureDetail(Id));
    }, [dispatch])

    useEffect(() => {
        if (props.getContentByType) {
            const result = props.getContentByType.getContentByType?.result;
            if (result && result.length > 0) {
                const newOptions = result.map((element, index) => ({
                    value: element.id,
                    label: element.title
                }));
                setOptions(newOptions);
            }
        }
    }, [props.getContentByType, dispatch]);

    useEffect(() => {
        if (props.getLectureDetail.done) {
            let result = props.getLectureDetail?.getLectureDetail?.result;
            if (result) {
                setTitle(result.title);
                setSubTitle(result.subTitle);
                setDisplayOrder(result.displayOrder);
                const newSelectedOptions = result.relatedContentList.map(element => ({
                    value: element.id,
                    label: element.title
                }));
                ssetSelectedOptions(newSelectedOptions);
                const values = newSelectedOptions.map(option => option.value);
                setAddingPeerContentList(values);
            }
            if (result && result.sections && Array.isArray(result.sections)) {
                const formattedSections = result.sections.map(section => ({
                    Id: section.id,
                    DisplayOrder: section.displayOrder,
                    Title: section.title,
                    SubTitle: section.subTitle
                }));
                setSections(formattedSections);
            }

        }
    }, [props.getLectureDetail]);
    console.log(subTitle)
    const handleUpdateSection = (index, field, value) => {
        const updatedSections = [...sections];
        updatedSections[index][field] = value;
        setSections(updatedSections);
    };
    const handleAddSection = () => {
        setSections([...sections, { Id: "00000000-0000-0000-0000-000000000000", DisplayOrder: 0, Title: '', SubTitle: '' }]);
    };
    const handleRemoveSection = (index, id) => {
        const updatedSections = sections.filter((_, i) => i !== index);
        setSections(updatedSections);
        setRemovingSections([...removingSections, id]);
    };

    useEffect(() => {
        if (mounted && props.updateLecture.done) {
            if (props.updateLecture.updateLecture?.hasError) {
                Swal.fire({
                    icon: 'error',
                    title: 'İşlem Başarısız',
                    text: props.updateLecture.updateLecture?.responseCode,
                    confirmButtonText: "Anladım",
                });
                return
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'İşlem Başarılı',
                    text: "Ders başarıyla güncellendi.",
                    confirmButtonText: "Anladım",
                });
                dispatch(resetUpdateLecture());
            }

            setMounted(false);

        }
    }, [mounted, props.updateLecture]);
    function handleSubmit() {
        dispatch(updateLecture(Id, displayOrder, title, subTitle, sections, removingSections, addingPeerContentList));
        setMounted(true);
    }

    // useEffect(() => {
    //     if (props.updateLecture.done) {
    //         console.log(props.updateLecture)

    //     }
    // }, [props.updateLecture])

    function handleDelete() {
        dispatch(deleteLecture(Id));
        setMounted(true);


    }

    useEffect(() => {
        if (mounted && props.deleteLecture.done) {
            if (props.deleteLecture.deleteLecture?.hasError) {
                Swal.fire({
                    icon: 'error',
                    title: 'İşlem Başarısız',
                    text: props.deleteLecture.deleteLecture?.responseCode,
                    confirmButtonText: "Anladım",
                });
                return
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'İşlem Başarılı',
                    text: "Ders silme işlemi başarılı.",
                    confirmButtonText: "Anladım",
                });
                dispatch(resetDeleteLecture());
                navigate("/lectures");
            }

            setMounted(false);

        }
    }, [mounted, props.deleteLecture]);


    return (
        <div>
            <Header />
            <div className="container mt-3">
                <nav className='mt-2' aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to={`/`}>Ana sayfa</Link></li>
                        <li class="breadcrumb-item"><Link to={`/lectures`}>Dersler</Link></li>
                        <li class="breadcrumb-item active" aria-current="page">Ders detayı</li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="d-flex justify-content-between">
                        <h1>Ders güncelle</h1>
                        <button onClick={handleAddSection} className="btn btn-outline-primary mt-3">Bölüm Ekle</button>
                    </div>
                    <div className="col mt-4 mb-5">

                        <div className="form-floating mb-3 mt-3">
                            <input
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                                defaultValue={title}
                                type="text"
                                className="form-control"
                                placeholder="Ders başlığı"
                            />
                            <label htmlFor="subtitle">Ders başlığı</label>
                        </div>
                        <div className="form-floating mb-3 mt-3">
                            <input
                                onChange={(e) => {
                                    setSubTitle(e.target.value);
                                }}
                                defaultValue={subTitle}
                                type="text"
                                className="form-control"
                                placeholder="Ders başlığı"
                            />
                            <label htmlFor="subtitle">Ders alt başlığı</label>
                        </div>

                        <div className="form-floating mb-3 mt-3">
                            <input
                                onChange={(e) => {
                                    setDisplayOrder(e.target.value);
                                }}
                                defaultValue={displayOrder}
                                type="number"
                                className="form-control"
                                placeholder="Ders başlığı"
                            />
                            <label htmlFor="subtitle">Sıralama</label>
                        </div>

                        <div className="card">
                            <div className="card-body">
                                {sections.map((section, index) => (
                                    <div>
                                        <SectionCard
                                            key={index}
                                            section={section}
                                            id={section.Id}
                                            index={index}
                                            onUpdate={handleUpdateSection}
                                            onRemove={handleRemoveSection}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="form-floating mt-3">
                            <select
                                onChange={(e) => {
                                    dispatch(getContentByType(parseInt(e.target.value)))
                                }}
                                className="form-select mb-3" id="floatingSelect" aria-label="Floating label select example">
                                <option selected>Lütfen seçiniz</option>
                                <option value="1">Blog Makalesi</option>
                                <option value="2">Aktivite</option>
                                <option value="3">Bilişsel Çarpıtma</option>
                                <option value="4">Duygu</option>
                                <option value="5">Ders</option>
                            </select>
                            <label htmlFor="floatingSelect">Ders eklemek istediğiniz içeriğin türünü seçin</label>
                        </div>
                        <Select
                            onChange={(selectedOptions) => {
                                const values = selectedOptions.map(option => option.value);
                                setAddingPeerContentList(values);
                                ssetSelectedOptions(selectedOptions);
                            }}
                            value={sselectedOptions}
                            isMulti
                            name="colors"
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Lütfen gruplanacak içerikleri seçiniz"
                        />
                        <button onClick={handleDelete} className='btn btn-outline-danger mt-3 float-start mb-5'>Dersi sil</button>
                        <button onClick={handleSubmit} className='btn btn-outline-success mt-3 float-end mb-5'>Ders güncelle</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(LectureDetail);