import {
    /** FETCH PROFESSIONS  */
    FETCH_LOGIN_FULFILLED,
    FETCH_LOGIN_REJECTED,
    FETCH_LOGIN_PENDING,
    LOGOUT
} from "../../actions/auth/login";
const token = localStorage.getItem("accessToken");
const initialState = token ?
    {
        done: false,
        error: {},
        spinner: false,
        isLogin: true,
    }
    :
    {
        done: false,
        error: {},
        spinner: false,
        isLogin: false,
    }

export default (state = initialState, action) => {
    
    switch (action.type) {
        case FETCH_LOGIN_PENDING:
            return {
                done: false,
                error: {},
                spinner: true,
                isLogin: false
            };
        case FETCH_LOGIN_FULFILLED:
            localStorage.setItem("accessToken", action.payload.result.accessToken)
            localStorage.setItem("user", JSON.stringify(action.payload.result.user))
            return {
                ...state,
                login: action.payload,
                done: true,
                error: {},
                spinner: false,
                isLogin: true
            };
        case FETCH_LOGIN_REJECTED:
            return {
                ...state,
                error: action.payload,
                done: false,
                spinner: false,
                isLogin: false
            };
        case LOGOUT:
            return {
                error: {},
                done: true,
                isLogin: false,

            };
        default:
            return state;
    }
};