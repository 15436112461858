import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Quill temalarından birini dahil edin

function SectionCard({ section, index, onUpdate, onRemove, id }) {
    return (
        <div className="card mb-3">
            <div className="card-header">
                <button onClick={() => onRemove(index, id)} className="btn btn-outline-danger float-end">Bölümü çıkar</button>
            </div>
            <div className="card-body">

                {/* Diğer input alanları aynı kalabilir */}

                <div className="form-floating mb-3 mt-3">
                    <ReactQuill
                        value={section.SubTitle}
                        onChange={(content) => onUpdate(index, 'SubTitle', content)}
                        modules={SectionCard.modules}
                        formats={SectionCard.formats}
                        bounds={'.app'}
                        placeholder="Alt Başlık Giriniz"
                    />
                </div>

            </div>
        </div>
    );
}

// Quill modüllerini ve formatlarını özelleştirebilirsiniz
SectionCard.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, 
         {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
    ],
};

SectionCard.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
];

export default SectionCard;
