import { Routes, Route, useLocation, Navigate, useNavigate, BrowserRouter } from "react-router-dom";
import './App.css';
import { connect } from "react-redux";
import Index from './components/Index'
import Login from "./components/auth/login";
import Contents from "./components/content/Contents";
import ContentDetail from "./components/content/ContentDetail";
import AddContent from "./components/content/AddContent";
import GroupList from "./components/group/GroupList";
import CreateGroup from "./components/group/CreateGroup";
import Lectures from "./components/lecture/Lectures";
import AddLecture from "./components/lecture/AddLecture";
import GroupDetail from "./components/group/GroupDetail";
import LectureDetail from "./components/lecture/LectureDetail";
import Tests from "./components/characterTest/Tests";
import TypeList from "./components/characterTest/TypeList";
import UpdateQuestion from "./components/characterTest/UpdateQuestion";
import UpdateAnswer from "./components/characterTest/UpdateAnswer";
import UpdateTest from "./reducers/character/updateTest";
import CreateTest from "./components/characterTest/CreateTest";
import TestDetail from "./components/characterTest/TestDetail";
import ResultList from "./components/testResult/ResultList";
import CreateResult from "./components/testResult/CreateResult";
import ResultDetail from "./components/testResult/ResultDetail";
function App(props) {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Index />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/contents" element={<Contents />}></Route>
        <Route path="/content-detail/:Id" element={<ContentDetail />}></Route>
        <Route path="/add-content" element={<AddContent />}></Route>
        <Route path="/group" element={<GroupList />}></Route>
        <Route path="/create-group" element={<CreateGroup />}></Route>
        <Route path="/lectures" element={<Lectures />}></Route>
        <Route path="/add-lecture" element={<AddLecture />}></Route>
        <Route path="/group-detail/:Id" element={<GroupDetail />}></Route>
        <Route path="/lecture-detail/:Id" element={<LectureDetail />}></Route>
        <Route path="/tests" element={<Tests />}></Route>
        <Route path="/type-list" element={<TypeList />}></Route>
        <Route path="/update-question/:Id" element={<UpdateQuestion />}></Route>
        <Route path="/update-answer/:Id" element={<UpdateAnswer />}></Route>
        <Route path="/update-test/:Id" element={<UpdateTest />}></Route>
        <Route path="/create-test" element={<CreateTest />}></Route>
        <Route path="/test-detail/:Id" element={<TestDetail />}></Route>
        <Route path="/list-test-result" element={<ResultList />}></Route>
        <Route path="/create-test-result" element={<CreateResult/>}></Route>
        <Route path="/detail-test-result/:Id" element={<ResultDetail/>}></Route>
      </Routes>
    </div >
  );
}

const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(App);
