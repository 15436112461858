import React, { useEffect, useState } from 'react'
import Header from '../layout/Header'
import { connect, useDispatch } from 'react-redux';
import { pagingResult } from '../../actions/result/pagingResult';
import { Link } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { typeList } from '../../actions/character/typeList';
import { resetUpdateResult, updateResult } from '../../actions/result/updateResult';
import Swal from 'sweetalert2';
import { deleteResult } from '../../actions/result/deleteResult';
function ResultList(props) {

  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [representingCharacterType, setRepresentingCharacterType] = useState(null);
  const [content, setContent] = useState(null);
  const [culture, setCulture] = useState("tr");
  const [lowerBound, setLowerBound] = useState(null);
  const [upperBound, setUpperBound] = useState(null);
  const [resultId, setResultId] = useState(null);

  useEffect(() => {
    dispatch(pagingResult(pageSize, pageNumber, culture, null))
    dispatch(typeList());
  }, [pageSize, pageNumber, culture, dispatch])

  const handleChangePage = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  // Toplam sayfa sayısını hesaplama
  const totalPageCount = Math.ceil(props.pagingResult?.pagingResult?.result.numberOfTotalEntities / pageSize);

  // Sayfalama bileşenini render etme
  const renderPagination = () => {
    let pages = [];
    for (let i = 0; i < totalPageCount; i++) {
      pages.push(
        <li key={i} className={`page-item ${pageNumber === i ? 'active' : ''}`} onClick={() => handleChangePage(i)}>
          <a className="page-link" href="#">{i + 1}</a>
        </li>
      );
    }
    return pages;
  };

  function handleUpdate() {
    dispatch(updateResult(resultId, representingCharacterType, content, culture, lowerBound, upperBound))
  }

  useEffect(() => {
    if (props.updateResult.done) {
      document.getElementById('closeModal').click();
      Swal.fire({
        icon: 'success',
        title: 'İşlem Başarılı',
        text: "Sonuç başarıyla güncellendi.",
      }).then((result) => {
        dispatch(resetUpdateResult())
        dispatch(pagingResult(pageSize, pageNumber, culture, null))
      })

    }
    if (props.updateResult.error !== false) {
      Swal.fire({
        icon: 'error',
        title: 'İşlem Başarısız',
        text: "Sonuç güncellenirken bir hata oluştu.",
        confirmButtonText: "Anladım",
      })
    }
  }, [props.updateResult])

  useEffect(() => {
    dispatch(pagingResult(pageSize, pageNumber, culture, null))
  }, [culture, dispatch])
  console.log(props.pagingResult?.pagingResult)

  useEffect(() => {
    if (props.deleteResult.done) {
      document.getElementById('closeModal').click();
      Swal.fire({
        icon: 'success',
        title: 'İşlem Başarılı',
        text: "Sonuç başarıyla silindi.",
      }).then((result) => {
        window.location.reload(false);
      })
    }
  }, [props.deleteResult])

  return (
    <div>
      <Header />
      <div className="container">
        <nav className='mt-2' aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={`/`}>Ana sayfa</Link></li>
            <li className="breadcrumb-item active" aria-current="page">İçerikler</li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-12 mt-3 mb-3">
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <h2>Karakter Test Sonuçları</h2>
                <div>
                  <small><a href="#"
                    onClick={() => setCulture("tr")}
                    className={`btn ${culture == "tr" ? `btn-success` : `btn-outline-success`} me-2`}>TR</a></small>
                  <small><a href="#"
                    onClick={() => setCulture("en")}
                    className={`btn ${culture == "en" ? `btn-success` : `btn-outline-success`} me-2`}>EN</a></small>
                  {/* <small><Link to={`/create-test`} className='btn btn-outline-success me-3'>Test sonucu</Link></small> */}
                  <small><Link to={`/create-test-result`} className='btn btn-outline-success'><i className="fa-light fa-plus"></i></Link></small>
                </div>
              </div>
              <div className="card-body">
                {props.pagingResult.spinner ?
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                  : null}
                {props.pagingResult.done ?
                  <ol className="list-group list-group-numbered">
                    {props.pagingResult.pagingResult?.result?.resultList?.length != 0 ?
                      props.pagingResult.pagingResult?.result?.resultList?.map((element, index) => {
                        return (
                          <div
                            key={index}
                          >
                            <li className="list-group-item d-flex justify-content-between align-items-start mb-1">
                              <div className="ms-2 me-auto">
                                <div className="fw-bold" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                  <a
                                    onClick={() => {
                                      setResultId(element.id);
                                      setRepresentingCharacterType(element.representingCharacterType);
                                      setContent(element.content);
                                      setCulture(element.culture);
                                      setLowerBound(element.lowerBound);
                                      setUpperBound(element.upperBound);
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target={`#detailModal${element.id}`}
                                    href="#">
                                    {element.content.substring(0, 50) + "..."}
                                  </a>
                                </div>                                                            </div>
                              <span className="badge bg-secondary rounded-pill">{element.culture}</span>
                            </li>
                            <div className="modal fade" id={`detailModal${element.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Sonucu güncelle</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="card mt-4">
                                      <div className="card-header d-flex justify-content-between">
                                        <h3>Test Sonucu Güncelle</h3>
                                        <button
                                          onClick={() => {
                                            dispatch(deleteResult(element.id))
                                          }}
                                          className='btn btn-outline-danger btn-sm'>Sil</button>
                                      </div>
                                      <div className="card-body">
                                        <div className="mb-3">
                                          <label htmlFor="characterType" className="form-label">Temsil edilen karakter tipi</label>
                                          <select
                                            onChange={(e) => setRepresentingCharacterType(parseInt(e.target.value))}
                                            defaultValue={element.representingCharacterType.toString()} // burada dönüşümü yaptık
                                            className="form-select"
                                            id="characterType"
                                            aria-label="Default select example"
                                          >
                                            <option>Lütfen seçiniz</option>
                                            {props.typeList.done ?
                                              props.typeList.characterTypeList.result.map((element, index) => {
                                                return (
                                                  <option value={element.enumValue}>{element.enumName}</option>
                                                )
                                              })
                                              : null}
                                          </select>
                                        </div>
                                        <div className="mb-3">
                                          <label htmlFor="exampleFormControlInput1" className="form-label">İçerik</label>
                                          <CKEditor
                                            editor={ClassicEditor}
                                            data={element.content}
                                            onChange={(event, editor) => {
                                              const data = editor.getData();
                                              setContent(data);
                                            }}
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <label htmlFor="characterType" className="form-label">Dil</label>
                                          <select
                                            onChange={(e) => setCulture(e.target.value)}
                                            defaultValue={element.culture}
                                            className="form-select"
                                            id="characterType"
                                            aria-label="Default select example">
                                            <option>Lütfen seçiniz</option>
                                            <option value="tr">Türkçe</option>
                                            <option value="en">English</option>
                                          </select>
                                        </div>
                                        <div className="mb-3">
                                          <label htmlFor="exampleFormControlInput11" className="form-label">Alt sınır</label>
                                          <input
                                            onChange={(e) => setLowerBound(parseInt(e.target.value))}
                                            value={lowerBound}
                                            type="number"
                                            className="form-control" id="exampleFormControlInput11" placeholder="Alt sınır" />
                                        </div>
                                        <div className="mb-3">
                                          <label htmlFor="exampleFormControlInput12" className="form-label">Üst sınır</label>
                                          <input
                                            onChange={(e) => setUpperBound(parseInt(e.target.value))}
                                            value={upperBound}
                                            type="number" className="form-control" id="exampleFormControlInput12" placeholder="Üst sınır" />
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button id='closeModal' type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button onClick={handleUpdate} className="btn btn-primary">Güncelle</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                      :
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">İçerik bulunamadı</div>
                          Henüz bir içerik bulunmamaktadır.
                        </div>
                      </li>
                    }

                  </ol>
                  : null}
              </div>
              <div className="card-footer">
                <nav aria-label="Page navigation" className='float-end'>
                  <ul className="pagination pagination-sm">
                    {renderPagination()}
                  </ul>
                </nav>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(ResultList);