import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid";
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_SUBSCRIBEUSER_FULFILLED = "FETCH_SUBSCRIBEUSER_FULFILLED";
export const FETCH_SUBSCRIBEUSER_REJECTED = "FETCH_SUBSCRIBEUSER_REJECTED";
export const FETCH_SUBSCRIBEUSER_PENDING = "FETCH_SUBSCRIBEUSER_PENDING";
export const RESET_SUBSCRIBEUSER = "RESET_SUBSCRIBEUSER";

export function subscribeUser(UserId, MarketProductId, ExpireDate) {
  const accessToken = localStorage.getItem("accessToken");
  return (dispatch) => {
    dispatch({
      type: "FETCH_SUBSCRIBEUSER",
      payload: axios({
        method: HttpMethod.POST,
        url: `${API_BASE}/api/v2/admin/assign-subscription-to-user`,
        data: { UserId, MarketProductId, ExpireDate },
        headers: {
          Authorization: "Bearer " + accessToken,
          "Accept-Language": "tr-tr",
          "Content-Type": "application/json",
          "Client-Id": createuuid(),
          "ngrok-skip-browser-warning": "69920"
        },
      }).then((result) => result.data),
    });
  };
}

export function resetSubscribeUser() {
  return (dispatch) => {
    dispatch({
      type: "RESET_SUBSCRIBEUSER",
    });
  };
}
