import React, { useEffect, useState } from 'react'
import Header from './layout/Header'
import { connect, useDispatch } from 'react-redux';
import { getContentByType } from '../actions/content/getContentByType';
import { ThreeDots } from 'react-loader-spinner';
import { marketProductList } from '../actions/market/marketProductList'
import { resetSearchUser, searchUser } from '../actions/user/searchUser';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import withReactContent from 'sweetalert2-react-content';
import { subscribeUser } from '../actions/user/subscribeUser'


function Index(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [contentType, setContentType] = useState(1);
    const [emotionType, setEmotionType] = useState();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [mounted, setMounted] = useState(false);
    const [marketProductId, setMarketProductId] = useState("")
    const [day, setDay] = useState();

    function convertToUTCFormat(localDateTime) {
        var date = new Date(localDateTime);
        var year = date.getUTCFullYear().toString().padStart(4, '0');
        var month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Ay 0'dan başlar, bu yüzden 1 ekliyoruz
        var day = date.getUTCDate().toString().padStart(2, '0');
        var hours = date.getUTCHours().toString().padStart(2, '0');
        var minutes = date.getUTCMinutes().toString().padStart(2, '0');
        var seconds = date.getUTCSeconds().toString().padStart(2, '0');
    
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
    
    // Örnek Kullanım:
    var localDateTime
    

    useEffect(() => {
        dispatch(marketProductList());
    }, [dispatch])

    function handleSearch() {
        dispatch(searchUser(phoneNumber));
    }

    useEffect(() => {
        if (mounted && props.subscribeUser.done) {
            console.log(props.subscribeUser.subscribeUser?.hasError)
            if (props.subscribeUser.subscribeUser?.hasError) {
                Swal.fire({
                    icon: 'error',
                    title: 'İşlem Başarısız',
                    text: props.subscribeUser.subscribeUser?.responseCode,
                    confirmButtonText: "Anladım",
                });
                return
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'İşlem Başarılı',
                    text: "Abonelik tanımlama işlemi başarılı.",
                    confirmButtonText: "Anladım",
                });
                dispatch(resetSearchUser());
            }
            setMounted(false);
        }
    }, [mounted, props.subscribeUser])



    function ekleTarihZamani(input) {
        const suan = new Date(); // Şu anki zamanı al
        const sonZaman = new Date(suan.getTime() + input * 60000); // Belirtilen dakika kadar ekleyerek yeni bir tarih oluştur

        // Tarihi istenen formatta döndür
        const yil = sonZaman.getFullYear();
        const ay = String(sonZaman.getMonth() + 1).padStart(2, '0'); // Ayı iki haneli yapar
        const gun = String(sonZaman.getDate()).padStart(2, '0'); // Günü iki haneli yapar
        const saat = String(sonZaman.getHours()).padStart(2, '0'); // Saati iki haneli yapar
        const dakika = String(sonZaman.getMinutes()).padStart(2, '0'); // Dakikayı iki haneli yapar
        const saniye = String(sonZaman.getSeconds()).padStart(2, '0'); // Saniyeyi iki haneli yapar

        const formatliTarih = `${yil}-${ay}-${gun}T${saat}:${dakika}:${saniye}`;
        return formatliTarih;
    }

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-4">
                    <div className="col-4">
                        <div className="card">
                            <div className="card-header">
                                <h4>Abonelik türleri</h4>
                            </div>
                            <div className="card-body">

                                {props.marketProductList.spinner ?
                                    <div className='d-flex justify-content-center'>
                                        <ThreeDots
                                            height="80"
                                            width="80"
                                            radius="9"
                                            color="#4fa94d"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                    : null}
                                {props.marketProductList.done ?
                                    <ol class="list-group list-group-numbered">
                                        {props.marketProductList.marketProductList?.result != 0 ?

                                            props.marketProductList.marketProductList?.result.map((element, index) => {
                                                return (
                                                    <li class="list-group-item d-flex justify-content-between align-items-start">
                                                       
                                                        <div class="ms-2 me-auto">
                                                            <div class="fw-bold">{element.trTitle}</div>
                                                            {element.storeType == 0 ?
                                                                <p>Apple Store</p>
                                                                :
                                                                <p>Play Store</p>
                                                            }
                                                        </div>
                                                    </li>
                                                )
                                            })

                                            : null}

                                    </ol>

                                    : null}


                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="row">
                            <div className="col-11">
                                <div class="form-floating mb-3">
                                    <input onChange={(e) => { setPhoneNumber(e.target.value) }} type="tel" class="form-control" id="floatingInput" placeholder="Kullanıcı ara" />
                                    <label for="floatingInput"><i class="fa-sharp fa-light fa-magnifying-glass"></i> Kullanıcı arama</label>
                                </div>
                            </div>
                            <div className="col-1 pt-1">
                                <button onClick={handleSearch} className='btn btn-outline-success btn-lg'><i class="fa-sharp fa-light fa-magnifying-glass"></i></button>
                            </div>
                        </div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Kullanıcı adı</th>
                                    <th scope="col">Telefon numarası</th>
                                    <th scope="col">Email adresi</th>
                                    <th scope="col">Abonelik durumu</th>
                                    <th scope='col'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.searchUser.spinner ?
                                    <div className='d-flex justify-content-center'>
                                        <ThreeDots
                                            height="80"
                                            width="80"
                                            radius="9"
                                            color="#4fa94d"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                    : null}
                                {props.searchUser.done ?
                                    props.searchUser.searchUser?.result != 0 ?
                                        props.searchUser.searchUser.result.map((element, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th scope="row">{index}</th>
                                                    <td>{element.name}</td>
                                                    <td>{element.phoneNumber}</td>
                                                    <td>{element.email}</td>
                                                    <td>
                                                        {element.isSubscriptionExpired ?
                                                            <button disabled className='btn btn-outline-success btn-sm '>Aktif</button>
                                                            :
                                                            <button disabled className='btn btn-outline-danger btn-sm'>Pasif</button>

                                                        }
                                                    </td>
                                                    <td><button data-bs-toggle="modal" data-bs-target={`#staticBackdrop${index}`} className='btn btn-outline-success btn-sm'>Süreli abonelik ver</button></td>
                                                    <div class="modal fade" id={`staticBackdrop${index}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                        <div class="modal-dialog modal-dialog-centered">
                                                            <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title" id="staticBackdropLabel">Süreli abonelik</h5>
                                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    {props.marketProductList.done ?
                                                                        <>
                                                                            <div class="form-floating mb-3">
                                                                                <select
                                                                                    onChange={(e) => {
                                                                                        setMarketProductId(e.target.value)
                                                                                    }}
                                                                                    required
                                                                                    class="form-select" id="floatingSelect">
                                                                                    <option selected>Aboneliğin türünü seçiniz</option>
                                                                                    {props.marketProductList.marketProductList?.result != 0 ?
                                                                                        props.marketProductList.marketProductList?.result.map((element, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <option value={element.id}>{`${element.storeType==1 ? `Play Store `: `App Store ` }${element.trTitle}`}</option>
                                                                                                </>
                                                                                            )
                                                                                        })

                                                                                        : null}
                                                                                </select>
                                                                                <label for="floatingSelect">Abonelik türünü seçiniz</label>
                                                                            </div>
                                                                            <div class="form-floating mb-3">
                                                                                <input
                                                                                    required
                                                                                    onChange={(e) => {
                                                                                        var utcDateTime = new Date(e.target.value);
                                                                                        console.log(utcDateTime)
                                                                                        setDay(utcDateTime);
                                                                                    }}
                                                                                    type="datetime-local" class="form-control" id="floatingInput" />
                                                                                <label for="floatingInput">Tanımlanan aboneliğin hangi zamana kadar olacağını seçin</label>
                                                                            </div>
                                                                        </>

                                                                        : null}
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Vazgeç</button>
                                                                    <button onClick={() => {
                                                                        console.log(element.id+"---"+marketProductId+"---"+day)
                                                                        dispatch(subscribeUser(element.id, marketProductId, day));
                                                                        setMounted(true);
                                                                    }} type="button" data-bs-dismiss="modal" class="btn btn-primary">Aboneliği tanımla</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </tr>
                                            )
                                        })

                                        : null
                                    : null}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);