import {
    /** FETCH PROFESSIONS  */
    FETCH_DELETELECTURE_FULFILLED,
    FETCH_DELETELECTURE_REJECTED,
    FETCH_DELETELECTURE_PENDING,
    RESET_DELETELECTURE
} from "../../actions/lecture/deleteLecture";
const initialState = {
    done: false,
    error: false,
    spinner: false
};

export default (state = initialState, action) => {

    switch (action.type) {
        case FETCH_DELETELECTURE_PENDING:
            return {
                done: false,
                error: false,
                spinner: true,
            };
        case FETCH_DELETELECTURE_FULFILLED:
            return {
                ...state,
                deleteLecture: action.payload,
                done: true,
                error: false,
                spinner: false,
                isLogin: true
            };
        case FETCH_DELETELECTURE_REJECTED:
            return {
                ...state,
                error: action.payload,
                done: false,
                spinner: false,
                isLogin: false
            };
        case RESET_DELETELECTURE:
            return {
                initialState,
                done: false,
                error: false,
                spinner: false
            };
        default:
            return state;
    }
};