import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Header from '../layout/Header';
import { ThreeDots } from 'react-loader-spinner';
import { formatDate } from '../../helpers/Converter';
import { getGroupDetail } from '../../actions/group/getGroupDetail';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { getContentByType } from '../../actions/content/getContentByType';
import { discardContentFromGroup, resetDiscardContentFromGroup } from '../../actions/group/discardContenFromGroup';
import { enrollContentToGroup, resetEnrollContentToGroup } from '../../actions/group/enrollContentToGroup';
import { updateContentGroup } from '../../actions/group/updateContentGroup';
import { deleteGroup, resetDeleteGroup } from '../../actions/group/deleteGroup';

function GroupDetail(props) {

    const { Id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [groupName, setGroupName] = useState("");
    const [displayOrder, setDisplayOrder] = useState("");
    const [contentType, setContentType] = useState();
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState();
    const [addingPeerContentList, setAddingPeerContentList] = useState();
    const [discardContentId, setDiscardContentId] = useState("");
    const [leadContent, setLeadContent] = useState("");
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        dispatch(getGroupDetail(Id))
    }, [dispatch])

    useEffect(() => {
        if (props.getGroupDetail.done) {
            let result = props.getGroupDetail.getGroupDetail.result
            setGroupName(result?.groupName);
            setDisplayOrder(result?.displayOrder);
            setContentType(result?.contentType);
            // setDiscardContentId(result?.id);
            setLeadContent(result?.leadContentId);
            dispatch(getContentByType(result.contentType));
        }
    }, [props.getGroupDetail]);


    useEffect(() => {
        if (props.getContentByType) {
            const result = props.getContentByType.getContentByType?.result;
            if (result && result.length > 0) {
                const newOptions = result.map((element, index) => ({
                    value: element.id,
                    label: element.title
                }));
                setOptions(newOptions);
            }
        }
    }, [props.getContentByType, dispatch]);




    useEffect(() => {
        if (mounted && props.discardContentFromGroup.done) {
            if (props.discardContentFromGroup?.discardContentFromGroup?.hasError) {
                Swal.fire({
                    icon: 'error',
                    title: 'İşlem Başarısız',
                    text: props.discardContentFromGroup.discardContentFromGroup?.responseCode,
                    confirmButtonText: "Anladım",
                });
                return
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'İşlem Başarılı',
                    text: "İçerik gruptan başarıyla çıkarıldı.",
                    confirmButtonText: "Anladım",
                });
                dispatch(getGroupDetail(Id));
                dispatch(resetDiscardContentFromGroup());
            }

            setMounted(false);
        }
    }, [mounted, props.discardContentFromGroup]);

    useEffect(() => {
        if (props.enrollContentToGroup.done) {
            Swal.fire({
                icon: 'success',
                title: 'İşlem Başarılı',
                text: "İçerikler başarıyla eklendi.",
                confirmButtonText: "Anladım",
            });
            dispatch(getGroupDetail(Id));
            dispatch(resetEnrollContentToGroup());
        }
    }, [props.enrollContentToGroup]);

    useEffect(() => {
        if (mounted && props.enrollContentToGroup.done) {
            if (props.enrollContentToGroup.enrollContentToGroup?.hasError) {
                Swal.fire({
                    icon: 'error',
                    title: 'İşlem Başarısız',
                    text: props.enrollContentToGroup.enrollContentToGroup?.responseCode,
                    confirmButtonText: "Anladım",
                });
                return
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'İşlem Başarılı',
                    text: "İçerikler başarıyla eklendi.",
                    confirmButtonText: "Anladım",
                });
                dispatch(resetEnrollContentToGroup());
            }

            setMounted(false);
        }
    }, [mounted, props.enrollContentToGroup])

    function enrollContent() {
        dispatch(enrollContentToGroup(Id, addingPeerContentList))
        setMounted(true);
    }
    function discardContent() {
        dispatch(discardContentFromGroup(Id, [discardContentId]));
        setMounted(true);
    }
    function updateGroup() {
        dispatch(updateContentGroup(Id, groupName, displayOrder, [], [], leadContent))
        setMounted(true);
    }
    function handleDelete() {
        dispatch(deleteGroup(Id));
        setMounted(true);


    }

    useEffect(() => {
        if (mounted && props.deleteGroup.done) {
            if (props.deleteGroup.deleteGroup?.hasError) {
                Swal.fire({
                    icon: 'error',
                    title: 'İşlem Başarısız',
                    text: props.deleteGroup.deleteGroup?.responseCode,
                    confirmButtonText: "Anladım",
                });
                return
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'İşlem Başarılı',
                    text: "Grup silme işlemi başarılı.",
                    confirmButtonText: "Anladım",
                });
                dispatch(resetDeleteGroup());
                navigate("/group");
            }

            setMounted(false);

        }
    }, [mounted, props.deleteGroup]);
    useEffect(() => {
        if (props.getGroupDetail.done) {
            const lead = props.getGroupDetail.getGroupDetail.result.contentList.find(e => e.isLead);
            if (lead) {
                setLeadContent(lead.id); // isLead true olanın id'sini ayarlıyoruz.
            }
        }
    }, [props.getGroupDetail.done, props.getGroupDetail.getGroupDetail?.result?.contentList]);


    return (
        <>
            <Header />
            <div className="container">
                <nav className='mt-2' aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={`/`}>Ana sayfa</Link></li>
                        <li className="breadcrumb-item"><Link to={`/group`}>Gruplar</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Grup detayı</li>
                    </ol>
                </nav>
                <div className="row">
                    {props.getGroupDetail.spinner ?
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                        : null}
                    {props.getGroupDetail.done ?
                        <div className="col-10">
                            <h2>Grup Detay</h2>
                            <div className="form-floating mb-3 mt-3">
                                <input
                                    onChange={(e) => {
                                        setGroupName(e.target.value);
                                    }}
                                    defaultValue={groupName}
                                    type="text"
                                    className="form-control"
                                    id="title" placeholder="Grup başlığı" />
                                <label htmlFor="title">Grup Adı</label>
                            </div>
                            <div className="form-floating mb-3 mt-3">
                                <input
                                    onChange={(e) => {
                                        setDisplayOrder(e.target.value);
                                    }}
                                    defaultValue={displayOrder}
                                    type="number"
                                    className="form-control"
                                    id="title" placeholder="Grup başlığı" />
                                <label htmlFor="title">İçerik sıralaması</label>
                            </div>
                            <div className="form-floating mb-3">
                                <select
                                    disabled
                                    onChange={(e) => {
                                        setContentType(e.target.value)
                                    }}
                                    defaultValue={contentType}
                                    className="form-select" id="contenttype">
                                    <option selected={contentType == 1 ? "true" : "false"} value={1}>Blog Makalesi</option>
                                    <option selected={contentType == 2 ? "true" : "false"} value={2}>Aktivite</option>
                                    <option selected={contentType == 3 ? "true" : "false"} value={3}>Bilişsel Çarpıtma</option>
                                    <option selected={contentType == 4 ? "true" : "false"} value={4}>Duygu</option>
                                    <option selected={contentType == 5 ? "true" : "false"} value={5}>Ders</option>
                                </select>
                                <label htmlFor="contenttype">İçerik türü</label>
                            </div>

                            <div className="form-floating mb-3">
                                <select
                                    onChange={(e) => setLeadContent(e.target.value)}
                                    value={leadContent} // Burada value'yu doğrudan kontrol ediyoruz.
                                    className="form-select"
                                    id="contenttype"
                                >
                                    {props.getGroupDetail.done &&
                                        props.getGroupDetail.getGroupDetail.result.contentList.map((element, index) => (
                                            <option key={index} value={element.id}>
                                                {element.title}
                                            </option>
                                        ))
                                    }
                                </select>
                                <label htmlFor="contenttype">Ana içerik</label>
                            </div>
                            <div className='mt-4'>
                                <h4>Grup içerikleri </h4>
                                <table className="table mt-3">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">İçerik başlığı</th>
                                            <th scope="col">İçerik alt başlığı</th>
                                            <th scope="col">İçerik yazarı</th>
                                            <th scope='col'>Eklenme tarihi</th>
                                            <th scope='col'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.getGroupDetail.done ?
                                            props.getGroupDetail.getGroupDetail?.result?.contentList.map((element, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{element.title}</td>
                                                        <td>{element.subTitle}</td>
                                                        <td>{element.author}</td>
                                                        <td>{formatDate(element.createdAt)}</td>
                                                        <td>
                                                            {props.discardContentFromGroup.spinner ?
                                                                <button key={index} class="btn btn-outline-danger btn-sm" type="button" disabled>
                                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    İşlem devam ediyor...
                                                                </button>
                                                                :
                                                                <button key={index} onClick={() => {
                                                                    dispatch(discardContentFromGroup(Id, [element.id]))
                                                                    setMounted(true);
                                                                }} className='btn btn-outline-danger btn-sm'>Gruptan çıkar</button>
                                                            }


                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            : null}
                                    </tbody>
                                </table>
                                <Select
                                    onChange={(selectedOptions) => {
                                        const values = selectedOptions.map(option => option.value);
                                        setAddingPeerContentList(values);
                                        setSelectedOptions(selectedOptions);
                                    }}
                                    defaultValue={[]}
                                    isMulti
                                    name="colors"
                                    options={options}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Lütfen gruplanacak içerikleri seçiniz"
                                />
                                <div className="form-floating mb-3">
                                    <select
                                        onChange={(e) => {
                                            setContentType(e.target.value)
                                        }}
                                        defaultValue={contentType}
                                        className="form-select mt-3" id="contenttype">
                                        <option value={1}>Blog Makalesi</option>
                                        <option value={2}>Aktivite</option>
                                        <option value={3}>Bilişsel Çarpıtma</option>
                                        <option value={4}>Duygu</option>
                                        <option value={5}>Ders</option>
                                    </select>
                                    <label htmlFor="contenttype">İlk içerik</label>
                                </div>
                            </div>
                            <div>
                                <button onClick={handleDelete} className='float-start btn btn-outline-danger btn-sm mt-2'>Grubu Sil</button>
                                <button onClick={enrollContent} className='float-end btn btn-outline-secondary btn-sm mt-2 ms-3'>Seçili içerikleri ekle</button>

                                <button onClick={updateGroup} className='float-end btn btn-outline-success btn-sm mt-2'>Grubu güncelle</button>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupDetail);