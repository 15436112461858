import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getContentDetail } from '../../actions/content/getContentDetail';
import Header from '../layout/Header';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ThreeDots } from 'react-loader-spinner';
import { formatDate } from '../../helpers/Converter';
import { resetUpdateContent, updateContent } from '../../actions/content/updateContent';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { deleteContent, resetDeleteContent } from '../../actions/content/deleteContent'
import { createImage } from '../../actions/gpt/createImage'
import S3 from 'aws-sdk/clients/s3';


function ContentDetail(props) {
    const { Id } = useParams();
    const dispatch = useDispatch();
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [displayOrder, setDisplayOrder] = useState("");
    const [content, setContent] = useState("");
    const [mounted, setMounted] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const navigate = useNavigate();

    const [file, setFile] = useState(null);

    const handleFileInput = (e) => {
        setFile(e.target.files[0]);
    };

    const uploadFile = async () => {
        const s3 = new S3({
            region: 'us-east-1', // S3 bucket bölgesi
            accessKeyId: "AKIAW2C2GUNPXWGYC3VO",
            secretAccessKey: "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",
        });

        const params = {
            Bucket: 'mentaskops3', // S3 bucket adı
            Key: file.name, // Dosya adı
            Body: file, // Yüklenecek dosya
            ACL: 'public-read' // Dosyanın herkese açık olmasını sağlar
        };
        console.log(file.name)

        try {
            const response = await s3.upload(params).promise();
            setImageUrl(response.Location)
            alert('Dosya başarıyla yüklendi!');
            console.log('Yüklenen Dosyanın URL\'si:', response.Location);
            
        } catch (err) {
            alert('Dosya yükleme hatası: ', err.message);
            console.log(err);
        }
    };


    useEffect(() => {
        dispatch(getContentDetail(Id))
    }, [dispatch])



    useEffect(() => {
        if (props.getContentDetail.done) {
            setTitle(props.getContentDetail.getContentDetail.result.title);
            setSubTitle(props.getContentDetail.getContentDetail.result.subTitle)
            setDisplayOrder(props.getContentDetail.getContentDetail.result.displayOrder);
            setContent(props.getContentDetail.getContentDetail.result.contentText);
            setImageUrl(props.getContentDetail.getContentDetail.result.imageUrl)

        }
    }, [props.getContentDetail]);



    useEffect(() => {
        if (props.updateContent.done) {
            Swal.fire({
                icon: 'success',
                title: 'İşlem Başarılı',
                text: "Verileriiniz başarıyla güncellendi.",
                confirmButtonText: "Anladım",
            })
            dispatch(resetUpdateContent())
        }
    }, [props.updateContent]);


    function HandleSubmit() {
        if (props.getContentDetail.done) {
            dispatch(
                updateContent(
                    props.getContentDetail.getContentDetail.result.id,
                    imageUrl,
                    subTitle,
                    title,
                    props.getContentDetail.getContentDetail.result.author,
                    displayOrder,
                    content,
                    props.getContentDetail.getContentDetail.result.isDeleted)
            );
            setMounted(true);
        }
    }

    

    function HandleDelete() {

        dispatch(deleteContent(Id))
        setMounted(true)

    }

    function handleCreateImage() {
        dispatch(createImage(title))
        console.log(title)
    }

    useEffect(() => {
        if (props.createImage.done) {
            setImageUrl(props.createImage.createImage.data[0].url)
        }
    }, [props.createImage])

    useEffect(() => {
        if (mounted && props.deleteContent.done) {
            if (props.deleteContent.deleteContent?.hasError) {
                Swal.fire({
                    icon: 'error',
                    title: 'İşlem Başarısız',
                    text: props.deleteContent.deleteContent?.responseCode,
                    confirmButtonText: "Anladım",
                });
                return
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'İşlem Başarılı',
                    text: "İçerik silme işlemi başarılı.",
                    confirmButtonText: "Anladım",
                });
                dispatch(resetDeleteContent());
                navigate("/contents");
            }
            setMounted(false);

        }
    }, [mounted, props.deleteContent]);

    return (
        <>
            <Header />

            <div className="container">
                <nav className='mt-2' aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to={`/`}>Ana sayfa</Link></li>
                        <li class="breadcrumb-item"><Link to={`/contents`}>İçerikler</Link></li>
                        <li class="breadcrumb-item active" aria-current="page">İçerik detayı</li>
                    </ol>
                </nav>
                {props.getContentDetail.spinner ?
                    <div className='d-flex justify-content-center'>
                        <ThreeDots
                            height="180"
                            width="180"
                            radius="9"
                            color="#7E5BED"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                    : null}
                {props.getContentDetail.done ?
                    <>
                        <div className="row mt-2">
                            <div className="col-4">
                                <img src={props.getContentDetail.getContentDetail.result.imageUrl} class="img-thumbnail mb-3" alt="..."></img>

                            </div>
                            <div className="col-8">

                                <p className='d-flex'>
                                    <b className='me-2 justify-content-start'>Metin başlığı: </b>
                                    <input
                                        onChange={(e) => {
                                            setTitle(e.target.value);
                                        }}
                                        defaultValue={props.getContentDetail.getContentDetail.result.title} type="text" class="form-control justify-content-end" />

                                </p>
                                <p className='d-flex'>
                                    <b className='me-2 justify-content-start'>Metin altbaşlığı: </b>
                                    <input
                                        onChange={(e) => {
                                            setSubTitle(e.target.value);
                                        }}
                                        defaultValue={props.getContentDetail.getContentDetail.result.subTitle} type="text" class="form-control justify-content-end" />

                                </p>
                                <p className='d-flex'>
                                    <b className='me-2 justify-content-start'>Metin görseli: </b>
                                    <input
                                        onChange={(e) => {
                                            setImageUrl(e.target.value);
                                        }}
                                        defaultValue={imageUrl} type="text" class="form-control justify-content-end" />

                                </p>
                                <div className='d-flex mb-3'>
                                    <input className='form-control' type="file" onChange={handleFileInput} />
                                    <button className='btn btn-outline-secondary btn-sm' onClick={uploadFile}>Yükle</button>
                                </div>
                                <p>
                                    <b className='me-2'>Yayınlanma tarihi: </b>
                                    <small>{formatDate(props.getContentDetail.getContentDetail.result.createdAt)}</small>
                                </p>
                                <p>
                                    <b className='me-2'>Dil: </b>
                                    <small>{props.getContentDetail.getContentDetail.result.culture}</small>
                                </p>
                                <p>
                                    <b className='me-2'>Yazar: </b>
                                    <small>{props.getContentDetail.getContentDetail.result.author}</small>
                                </p>
                                <p className='d-flex'>
                                    <b className='justify-content-start pt-1 me-2'>Sırlama: </b>
                                    <input
                                        onChange={(e) => {
                                            setDisplayOrder(parseInt(e.target.value))
                                        }}
                                        defaultValue={props.getContentDetail.getContentDetail.result.displayOrder == null ? 0 : props.getContentDetail.getContentDetail.result.displayOrder} type="text" className='form-control justify-content-end' />
                                </p>
                            </div>
                        </div>
                        <div className="row mt-2">

                            <div className="">
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={props.getContentDetail.getContentDetail.result.contentText}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setContent(data);
                                    }}
                                    onBlur={(event, editor) => {
                                        // console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // console.log('Focus.', editor);
                                    }}
                                />
                                <div className='float-end'>
                                    {props.updateContent.spinner ?
                                        <button class="btn btn-outline-danger mt-2" type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            İşlem devam ediyor...
                                        </button>
                                        :
                                        <button onClick={HandleDelete} className='btn btn-outline-danger mt-2'>Sil</button>
                                    }


                                </div>
                                <div className='float-start'>
                                    {props.updateContent.spinner ?
                                        <button class="btn btn-outline-secondary mt-2" type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            İşlem devam ediyor...
                                        </button>
                                        :
                                        <button onClick={HandleSubmit} className='btn btn-outline-secondary mt-2'>Güncelle</button>
                                    }
                                    {props.createImage.spinner ?
                                        <button class="btn btn-outline-secondary mt-2" type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            İşlem devam ediyor...
                                        </button>
                                        :
                                        <button onClick={handleCreateImage} className='btn btn-outline-primary mt-2 ms-2'>Yapay zeka ile görsel üret</button>
                                    }


                                </div>
                            </div>
                        </div>
                    </>
                    : null}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(ContentDetail);