import React, { useEffect, useState } from 'react'
import Header from '../layout/Header'
import { connect, useDispatch } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import {getLectureList} from '../../actions/lecture/getLectureList'

function Lectures(props) {
    const dispatch = useDispatch();
    const [contentType, setContentType] = useState(1);
    const [emotionType, setEmotionType] = useState();




    useEffect(() => {
        dispatch(getLectureList());
    }, [dispatch])
    // useEffect(() => {
    //     console.log(props)
    // }, [props.getLectureList])

    return (
        <>
            <Header />
            <div className="container">
            <nav className='mt-2' aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to={`/`}>Ana sayfa</Link></li>
                        <li class="breadcrumb-item active" aria-current="page">Dersler</li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-12 mt-3 mb-3">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h2>Dersler</h2>
                                <small><Link to={`/add-lecture`} className='btn btn-outline-success'><i class="fa-light fa-plus"></i></Link></small>
                            </div>
                            <div className="card-body">
                                {props.getLectureList.spinner ?
                                    <ThreeDots
                                        height="80"
                                        width="80"
                                        radius="9"
                                        color="#4fa94d"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                    : null}
                                {props.getLectureList.done ?
                                    <ol class="list-group list-group-numbered">
                                        {props.getLectureList.getLectureList?.result?.length != 0 ?
                                            props.getLectureList.getLectureList?.result.map((element, index) => {
                                                return (
                                                    <Link
                                                        to={`/lecture-detail/${element.id}`}
                                                    >
                                                        <li key={index} class="list-group-item d-flex justify-content-between align-items-start mb-1">
                                                            <div class="ms-2 me-auto">
                                                                <div class="fw-bold">{element.title}</div>
                                                                {element.author}
                                                            </div>
                                                            <span class="badge bg-secondary rounded-pill">{element.culture}</span>
                                                        </li>
                                                    </Link>
                                                )
                                            })
                                            :
                                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                                <div class="ms-2 me-auto">
                                                    <div class="fw-bold">İçerik bulunamadı</div>
                                                    Henüz bir içerik bulunmamaktadır.
                                                </div>
                                            </li>
                                        }

                                    </ol>
                                    : null}
                            </div>
                            <div className="card-footer">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Lectures);