import React from 'react'
import Header from '../layout/Header'

function UpdateAnswer() {
    return (
        <div>
            <Header />
            <h1>UpdateAnswer</h1>
        </div>
    )
}

export default UpdateAnswer

