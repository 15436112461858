import {
    /** FETCH PROFESSIONS  */
    FETCH_DELETERESULT_FULFILLED,
    FETCH_DELETERESULT_REJECTED,
    FETCH_DELETERESULT_PENDING,
    RESET_DELETERESULT
} from "../../actions/result/deleteResult";
const initialState = {
    done: false,
    error: false,
    spinner: false
};

export default (state = initialState, action) => {

    switch (action.type) {
        case FETCH_DELETERESULT_PENDING:
            return {
                done: false,
                error: false,
                spinner: true,
            };
        case FETCH_DELETERESULT_FULFILLED:
            return {
                ...state,
                deleteResult: action.payload,
                done: true,
                error: false,
                spinner: false,
            };
        case FETCH_DELETERESULT_REJECTED:
            return {
                ...state,
                error: action.payload,
                done: false,
                spinner: false,
            };
        case RESET_DELETERESULT:
            return {
                initialState,
                done: false,
                error: false,
                spinner: false
            };
        default:
            return state;
    }
};