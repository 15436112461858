import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import Header from '../layout/Header';
import Select from 'react-select'
import { useNavigate } from 'react-router';
import { getContentByType } from '../../actions/content/getContentByType';
import { createContentGroup } from '../../actions/group/createContentGroup';
import Swal from 'sweetalert2';
import { resetCreateContent } from '../../actions/content/createContent';

function CreateGroup(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [mounted, setMounted] = useState(false);

    const [groupName, setGroupName] = useState("");
    const [displayOrder, setDisplayOrder] = useState(0);
    const [addingPeerContentList, setAddingPeerContentList] = useState();
    const [leadContentId, setLeadContentId] = useState("");
    const [options, setOptions] = useState([]);
    const [sselectedOptions, ssetSelectedOptions] = useState();
    useEffect(() => {
        dispatch(getContentByType(1));
    }, [])




    useEffect(() => {
        if (props.getContentByType) {
            const result = props.getContentByType.getContentByType?.result;
            if (result && result.length > 0) {
                const newOptions = result.map((element, index) => ({
                    value: element.id,
                    label: element.title
                }));
                setOptions(newOptions);
            }
        }
    }, [props.getContentByType, dispatch]);

    function handleSubmit() {
        setMounted(true);
        dispatch(createContentGroup(groupName, displayOrder, addingPeerContentList, leadContentId));
    }

    useEffect(() => {
        if (mounted && props.createContentGroup.done) {
            if (props.createContentGroup.createContentGroup?.hasError) {
                Swal.fire({
                    icon: 'error',
                    title: 'İşlem Başarısız',
                    text: props.createContentGroup.createContentGroup?.responseCode,
                    confirmButtonText: "Anladım",
                });
                return
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'İşlem Başarılı',
                    text: "Grup başarıyla oluşturuldu.",
                    confirmButtonText: "Anladım",
                });
                dispatch(resetCreateContent());
            }

            setMounted(false);
            navigate("/group");

        }
    }, [mounted, props.createContentGroup])

    return (
        <div>
            <Header />
            <div className="container mt-3">
                <div className="row">
                    <div className="col">
                        <h1>Grup Oluştur</h1>
                        <div className="form-floating mb-3 mt-3">
                            <input
                                onChange={(e) => {
                                    setGroupName(e.target.value);
                                }}
                                type="text" className="form-control" id="subtitle" placeholder="Grup adı" />
                            <label htmlFor="subtitle">Grup adı</label>
                        </div>
                        <div className="form-floating mb-3 mt-3">
                            <input
                                onChange={(e) => {
                                    setDisplayOrder(parseInt(e.target.value));
                                }}
                                type="text" className="form-control" id="displayorder" placeholder="Örn: 12" />
                            <label htmlFor="displayorder">Sıralama</label>
                        </div>
                        <div className="form-floating">
                            <select
                                onChange={(e) => {
                                    dispatch(getContentByType(parseInt(e.target.value)))
                                }}
                                className="form-select mb-3" id="floatingSelect" aria-label="Floating label select example">
                                <option selected>Lütfen seçiniz</option>
                                <option value="1">Blog Makalesi</option>
                                <option value="2">Aktivite</option>
                                <option value="3">Bilişsel Çarpıtma</option>
                                <option value="4">Duygu</option>
                                <option value="5">Ders</option>
                            </select>
                            <label htmlFor="floatingSelect">Gruba eklemek istediğiniz içeriğin türünü seçiniz</label>
                        </div>
                        <Select
                            onChange={(selectedOptions) => {
                                const values = selectedOptions.map(option => option.value);
                                setAddingPeerContentList(values);
                                ssetSelectedOptions(selectedOptions);
                            }}
                            defaultValue={[]}
                            isMulti
                            name="colors"
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Lütfen gruplanacak içerikleri seçiniz"
                        />
                        <Select
                            onChange={(e) => {
                                setLeadContentId(e.value);
                            }}
                            placeholder="Lütfen ilk içeriği seçiniz"
                            options={sselectedOptions} className='mt-3' />
                        <div className='d-flex justify-content-end'>
                            <button onClick={handleSubmit} className='btn btn-outline-success mt-3'>Grubu oluştur</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);