import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid"
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_ENROLLCONTENTTOGROUP_FULFILLED = "FETCH_ENROLLCONTENTTOGROUP_FULFILLED";
export const FETCH_ENROLLCONTENTTOGROUP_REJECTED = "FETCH_ENROLLCONTENTTOGROUP_REJECTED";
export const FETCH_ENROLLCONTENTTOGROUP_PENDING = "FETCH_ENROLLCONTENTTOGROUP_PENDING";
export const RESET_ENROLLCONTENTTOGROUP = "RESET_ENROLLCONTENTTOGROUP";

export function enrollContentToGroup(ContentGroupId, AddingContentIdList) {
    const accessToken = localStorage.getItem('accessToken');
    return (dispatch) => {
        dispatch({
            type: "FETCH_ENROLLCONTENTTOGROUP",
            payload: axios({
                method: HttpMethod.POST,
                url: `${API_BASE}/api/v2/admin/enroll-content-to-group`,
                data: { ContentGroupId, AddingContentIdList },
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    "Client-Id": createuuid(),
                    "ngrok-skip-browser-warning": "69920"
                },
            }).then((result) => result.data)
        });
    };
}

export function resetEnrollContentToGroup() {
    return (dispatch) => {
        dispatch({
            type: "RESET_ENROLLCONTENTTOGROUP",
        });
    };
}




