import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid"
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_CREATELECTURE_FULFILLED = "FETCH_CREATELECTURE_FULFILLED";
export const FETCH_CREATELECTURE_REJECTED = "FETCH_CREATELECTURE_REJECTED";
export const FETCH_CREATELECTURE_PENDING = "FETCH_CREATELECTURE_PENDING";
export const RESET_CREATELECTURE = "RESET_CREATELECTURE";

export function createLecture(DisplayOrder,Title,SubTitle,Sections,ConnectingContentIdList) {
    const accessToken = localStorage.getItem('accessToken');
    return (dispatch) => {
        dispatch({
            type: "FETCH_CREATELECTURE",
            payload: axios({
                method: HttpMethod.POST,
                url: `${API_BASE}/api/v2/admin/create-lecture`,
                data: {DisplayOrder,Title,SubTitle,Sections,ConnectingContentIdList},
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    "Client-Id": createuuid(),
                    "ngrok-skip-browser-warning": "69920"
                },
            }).then((result) => result.data)
        });
    };
}

export function resetCreateLecture() {
    return (dispatch) => {
        dispatch({
            type: "RESET_CREATELECTURE",
        });
    };
  }




