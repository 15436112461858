import React, { useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import Header from '../layout/Header';
import { useNavigate } from 'react-router';
import SectionCard from './SectionCard';
import { getContentByType } from '../../actions/content/getContentByType';
import { createLecture, resetCreateLecture } from '../../actions/lecture/createLecture';
import Select from 'react-select'
import Swal from 'sweetalert2';

function AddLecture(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [displayOrder, setDisplayOrder] = useState(0);
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [sselectedOptions, ssetSelectedOptions] = useState([]);
    const [addingPeerContentList, setAddingPeerContentList] = useState();
    const [options, setOptions] = useState([]);
    const [mounted, setMounted] = useState(false);




    const [sections, setSections] = useState([
        {
            "DisplayOrder": 0,
            "Title": null,
            "SubTitle": null
        }
    ]);

    const handleUpdateSection = (index, field, value) => {
        const updatedSections = [...sections];
        updatedSections[index][field] = value;
        setSections(updatedSections);
    };

    const handleAddSection = () => {
        setSections([...sections, { DisplayOrder: 0, Title: '', SubTitle: '' }]);
    };

    const handleRemoveSection = (index) => {
        const updatedSections = sections.filter((_, i) => i !== index);
        setSections(updatedSections);
    };

    useEffect(() => {
        if (props.getContentByType) {
            const result = props.getContentByType.getContentByType?.result;
            if (result && result.length > 0) {
                const newOptions = result.map((element, index) => ({
                    value: element.id,
                    label: element.title
                }));
                setOptions(newOptions);
            }
        }
    }, [props.getContentByType, dispatch]);

    useEffect(() => {
        dispatch(getContentByType(1));
    }, [])

    function handleSubmit() {
        dispatch(createLecture(displayOrder,title,subTitle,sections,addingPeerContentList));
        setMounted(true);
    }

    useEffect(() => {
        if (mounted && props.createLecture.done) {
            if (props.createLecture.createLecture?.hasError) {
                Swal.fire({
                    icon: 'error',
                    title: 'İşlem Başarısız',
                    text: props.createLecture.createLecture?.responseCode,
                    confirmButtonText: "Anladım",
                });
                return
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'İşlem Başarılı',
                    text: "İçerik başarıyla oluşturuldu.",
                    confirmButtonText: "Anladım",
                });
                dispatch(resetCreateLecture());
                navigate("/lectures");
            }

            setMounted(false);
            

        }
    }, [mounted, props.createLecture])


    return (
        <div>
            <Header />
            <div className="container mt-3">
                <div className="row">
                    <div className="d-flex justify-content-between">
                        <h1>Ders ekle</h1>
                        <button onClick={handleAddSection} className="btn btn-outline-primary mt-3">Bölüm Ekle</button>
                    </div>
                    <div className="col mt-4 mb-5">

                        <div className="form-floating mb-3 mt-3">
                            <input
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                                type="text"
                                className="form-control"
                                placeholder="Ders başlığı"
                            />
                            <label htmlFor="subtitle">Ders başlığı</label>
                        </div>
                        <div className="form-floating mb-3 mt-3">
                            <input
                                onChange={(e) => {
                                    setSubTitle(e.target.value);
                                }}
                                type="text"
                                className="form-control"
                                placeholder="Ders başlığı"
                            />
                            <label htmlFor="subtitle">Ders alt başlığı</label>
                        </div>

                        <div className="form-floating mb-3 mt-3">
                            <input
                                onChange={(e) => {
                                    setDisplayOrder(e.target.value);
                                }}
                                type="number"
                                className="form-control"
                                placeholder="Ders başlığı"
                            />
                            <label htmlFor="subtitle">Sıralama</label>
                        </div>

                        <div className="card">
                            <div className="card-body">
                                {sections.map((section, index) => (
                                    <SectionCard
                                        key={index}
                                        section={section}
                                        index={index}
                                        onUpdate={handleUpdateSection}
                                        onRemove={handleRemoveSection}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="form-floating mt-3">
                            <select
                                onChange={(e) => {
                                    dispatch(getContentByType(parseInt(e.target.value)))
                                }}
                                className="form-select mb-3" id="floatingSelect" aria-label="Floating label select example">
                                <option selected>Lütfen seçiniz</option>
                                <option value="1">Blog Makalesi</option>
                                <option value="2">Aktivite</option>
                                <option value="3">Bilişsel Çarpıtma</option>
                                <option value="4">Duygu</option>
                                <option value="5">Ders</option>
                            </select>
                            <label htmlFor="floatingSelect">Ders eklemek istediğiniz içeriğin türünü seçin</label>
                        </div>
                        <Select
                            onChange={(selectedOptions) => {
                                const values = selectedOptions.map(option => option.value);
                                setAddingPeerContentList(values);
                                ssetSelectedOptions(selectedOptions);
                            }}
                            defaultValue={[]}
                            isMulti
                            name="colors"
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Lütfen gruplanacak içerikleri seçiniz"
                        />
                        <button onClick={handleSubmit} className='btn btn-outline-success mt-3 mb-5 float-end'>Ders ekle</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(AddLecture);