import { combineReducers } from "redux";
import login from "./auth/login"
import getContentByType from "./content/getContentByType";
import getContentByEmotionType from "./content/getContentByEmotionType";
import getContentGroupList from "./group/getContentGroupList";
import getLectureList from "./lecture/getLectureList";
import updateContent from "./content/updateContent";
import createContent from "./content/createContent";
import createContentGroup from "./group/createContentGroup";
import enrollContentToGroup from "./group/enrollContentToGroup";
import createLecture from "./lecture/createLecture";
import discardLecture from "./lecture/discardLecture";
import enrollLecture from "./lecture/enrollLecture";
import searchUser from "./user/searchUser";
import subscribeUser from "./user/subscribeUser";
import getContentDetail from "./content/getContentDetail";
import marketProductList from "./market/marketProductList";
import getGroupDetail from "./group/getGroupDetail";
import getLectureDetail from "./lecture/getLectureDetail";
import discardContentFromGroup from "./group/discardContentFromGroup";
import updateContentGroup from "./group/updateContentGroup";
import updateLecture from "./lecture/updateLecture";
import deleteContent from "./content/deleteContent";
import deleteGroup from "./group/deleteGroup";
import deleteLecture from "./lecture/deleteLecture";
import createImage from "./gpt/createImage";
import createTest from "./character/createTest";
import typeList from "./character/typeList";
import pagingTest from "./character/pagingTest";
import updateTest from "./character/updateTest";
import updateTestQuestion from "./character/updateTestQuestion";
import updateTestAnswer from "./character/updateTestAnswer";
import createTestQuestion from "./character/createTestQuestion";
import createTestAnswer from "./character/createTestAnswer";
import getTest from "./character/getTest";
import fileUploadV2 from "./fileUpload/fileUploadV2";
import createResult from "./result/createResult";
import updateResult from "./result/updateResult";
import pagingResult from "./result/pagingResult";
import deleteResult from "./result/deleteResult";

export default combineReducers({
    login,
    getContentByType,
    getContentByEmotionType,
    getContentGroupList,
    getLectureList,
    updateContent,
    createContent,
    createContentGroup,
    enrollContentToGroup,
    createLecture,
    discardLecture,
    enrollLecture,
    searchUser,
    subscribeUser,
    getContentDetail,
    marketProductList,
    getGroupDetail,
    getLectureDetail,
    discardContentFromGroup,
    updateContentGroup,
    updateLecture,
    deleteContent,
    deleteGroup,
    deleteLecture,
    createImage,
    createTest,
    typeList,
    pagingTest,
    updateTest,
    updateTestQuestion,
    updateTestAnswer,
    createTestQuestion,
    createTestAnswer,
    getTest,
    fileUploadV2,
    createResult,
    updateResult,
    pagingResult,
    deleteResult
});