import {
    /** FETCH PROFESSIONS  */
    FETCH_UPLOADFILEV2_FULFILLED,
    FETCH_UPLOADFILEV2_REJECTED,
    FETCH_UPLOADFILEV2_PENDING,
    RESET_UPLOADFILEV2
} from "../../actions/fileupload/fileUploadv2";
const initialState = {
    done: false,
    error: false,
    spinner: false
};

export default (state = initialState, action) => {

    switch (action.type) {
        case FETCH_UPLOADFILEV2_PENDING:
            return {
                done: false,
                error: false,
                spinner: true,
            };
        case FETCH_UPLOADFILEV2_FULFILLED:
            return {
                ...state,
                fileUploadv2: action.payload,
                done: true,
                error: false,
                spinner: false,
            };
        case FETCH_UPLOADFILEV2_REJECTED:
            return {
                ...state,
                error: action.payload,
                done: false,
                spinner: false,
            };
        case RESET_UPLOADFILEV2:
            return {
                initialState,
                done: false,
                error: false,
                spinner: false
            };
        default:
            return state;
    }
};