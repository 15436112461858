import {
    /** FETCH PROFESSIONS  */
    FETCH_UPDATETESTQUESTION_FULFILLED,
    FETCH_UPDATETESTQUESTION_REJECTED,
    FETCH_UPDATETESTQUESTION_PENDING,
    RESET_UPDATETESTQUESTION
} from "../../actions/character/updateTestQuestion";
const initialState = {
    done: false,
    error: false,
    spinner: false
};

export default (state = initialState, action) => {

    switch (action.type) {
        case FETCH_UPDATETESTQUESTION_PENDING:
            return {
                done: false,
                error: false,
                spinner: true,
            };
        case FETCH_UPDATETESTQUESTION_FULFILLED:
            return {
                ...state,
                updateTestQuestion: action.payload,
                done: true,
                error: false,
                spinner: false,
            };
        case FETCH_UPDATETESTQUESTION_REJECTED:
            return {
                ...state,
                error: action.payload,
                done: false,
                spinner: false,
            };
        case RESET_UPDATETESTQUESTION:
            return {
                initialState,
                done: false,
                error: false,
                spinner: false
            };
        default:
            return state;
    }
};