import {
  /** FETCH PROFESSIONS  */
  FETCH_CREATEIMAGE_FULFILLED,
  FETCH_CREATEIMAGE_REJECTED,
  FETCH_CREATEIMAGE_PENDING,
  RESET,
} from "../../actions/gpt/createImage";
const initialState = {
  done: false,
  error: false,
  spinner: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CREATEIMAGE_PENDING:
      return {
        done: false,
        error: false,
        spinner: true,
      };
    case FETCH_CREATEIMAGE_FULFILLED:
      return {
        ...state,
        createImage: action.payload,
        done: true,
        error: false,
        spinner: false,
        isLogin: true,
      };
    case FETCH_CREATEIMAGE_REJECTED:
      return {
        ...state,
        error: action.payload,
        done: false,
        spinner: false,
        isLogin: false,
      };
    case RESET:
      return {
        initialState,
        done: false,
        error: false,
        spinner: false,
      };
    default:
      return state;
  }
};
