import {
    /** FETCH PROFESSIONS  */
    FETCH_CREATETESTANSWER_FULFILLED,
    FETCH_CREATETESTANSWER_REJECTED,
    FETCH_CREATETESTANSWER_PENDING,
    RESET_CREATETESTANSWER
} from "../../actions/character/createTestAnswer";
const initialState = {
    done: false,
    error: false,
    spinner: false
};

export default (state = initialState, action) => {

    switch (action.type) {
        case FETCH_CREATETESTANSWER_PENDING:
            return {
                done: false,
                error: false,
                spinner: true,
            };
        case FETCH_CREATETESTANSWER_FULFILLED:
            return {
                ...state,
                createTestAnswer: action.payload,
                done: true,
                error: false,
                spinner: false,
            };
        case FETCH_CREATETESTANSWER_REJECTED:
            return {
                ...state,
                error: action.payload,
                done: false,
                spinner: false,
            };
        case RESET_CREATETESTANSWER:
            return {
                initialState,
                done: false,
                error: false,
                spinner: false
            };
        default:
            return state;
    }
};