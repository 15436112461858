import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid"
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_UPDATETEST_FULFILLED = "FETCH_UPDATETEST_FULFILLED";
export const FETCH_UPDATETEST_REJECTED = "FETCH_UPDATETEST_REJECTED";
export const FETCH_UPDATETEST_PENDING = "FETCH_UPDATETEST_PENDING";
export const RESET_UPDATETEST = "RESET_UPDATETEST";


export function updateTest(
    testId,
    title,
    culture,
    isOnline,
    isDeleted,
    displayOrder
) {
    const accessToken = localStorage.getItem('accessToken');
    return (dispatch) => {
        dispatch({
            type: "FETCH_UPDATETEST",
            payload: axios({
                method: HttpMethod.POST,
                url: `${API_BASE}/api/v2/admin/update-character-test`,
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    "Client-Id": createuuid(),
                    "One-Id": createuuid()
                },
                data: {
                    testId,
                    title,
                    culture,
                    isOnline,
                    isDeleted,
                    displayOrder
                }
            }).then((result) => result.data)
        });
    };
}

export function resetUpdateTest() {
    return (dispatch) => {
        dispatch({
            type: "RESET_UPDATETEST",
        });
    };
}




