import React from 'react'
import Header from '../layout/Header';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { createCharacterTest, resetCreateCharacterTest } from '../../actions/character/createTest';
import Swal from 'sweetalert2';
import { typeList } from '../../actions/character/typeList'
import { fileUploadv2 } from '../../actions/fileupload/fileUploadv2';


function CreateTest(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [fileUrl, setfileUrl] = useState(null);



    function handleUpload() {
        dispatch(fileUploadv2(file))
    }


    const [formValues, setFormValues] = useState({
        title: '',
        culture: '',
        isOnline: false,
        displayOrder: 0,
        questions: []
    });

    useEffect(() => {
        dispatch(typeList());
    }, [dispatch])

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Başlık zorunlu'),
        culture: Yup.string().required('Kültür zorunlu'),
        isOnline: Yup.boolean(),
        displayOrder: Yup.number().min(0).required('Gösterim sırası zorunlu'),
        questions: Yup.array().of(
            Yup.object().shape({
                text: Yup.string().required('Soru metni zorunlu'),
                videoUrl: Yup.string(),
                displayOrder: Yup.number().min(0),
                representingCharacterType: Yup.number().min(0),
                answers: Yup.array().of(
                    Yup.object().shape({
                        text: Yup.string().required('Cevap metni zorunlu'),
                        weight: Yup.number().min(0),
                        displayOrder: Yup.number().min(0)
                    })
                )
            })
        )
    });

    const handleSubmit = (values) => {
        // console.log(values)
        dispatch(createCharacterTest(values))
    };

    useEffect(() => {

        if (props.createTest.done) {
            Swal.fire({
                title: 'Başarılı!',
                text: 'Test başarıyla oluşturuldu.',
                icon: 'success',
                confirmButtonText: 'Tamam'
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(resetCreateCharacterTest());
                    navigate('/tests')
                }
            })
        }

    }, [props.createTest])



    useEffect(() => {

        if (props.fileUploadV2.done) {
            const newFileUrl = props.fileUploadV2.fileUploadv2.result;
            setfileUrl(newFileUrl);
            return;
        }
        if (props.fileUploadV2.error != false) {
            Swal.fire({
                title: 'Hata!',
                text: 'Dosya yüklenirken bir hata oluştu.',
                icon: 'error',
                confirmButtonText: 'Tamam'
            })
            return;
        }


    }, [props.fileUploadV2])



    return (
        <div>
            <Header />
            <div className="container">
                <Formik initialValues={formValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Test Başlığı</label>
                                <input
                                    type="text"
                                    className={`form-control ${touched.title && errors.title ? 'is-invalid' : ''}`}
                                    name="title"
                                    id="title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title}
                                />
                                {touched.title && errors.title ? <div className="invalid-feedback">{errors.title}</div> : null}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="culture" className="form-label">Dil</label>
                                <select
                                    className="form-select"
                                    name="culture"
                                    id="culture"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.culture}
                                >
                                    <option value="">Seçiniz</option>
                                    <option value="tr">Türkçe</option>
                                    <option value="en">İngilizce</option>
                                </select>
                            </div>

                            <div className="mb-3 form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="isOnline"
                                    id="isOnline"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    checked={values.isOnline}
                                />
                                <label className="form-check-label" htmlFor="isOnline">Çevrimiçi</label>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="displayOrder" className="form-label">Gösterim Sırası</label>
                                <input
                                    type="number"
                                    className={`form-control ${touched.displayOrder && errors.displayOrder ? 'is-invalid' : ''}`}
                                    name="displayOrder"
                                    id="displayOrder"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.displayOrder}
                                />
                                {touched.displayOrder && errors.displayOrder ? <div className="invalid-feedback">{errors.displayOrder}</div> : null}
                            </div>

                            <FieldArray name="questions">
                                {({ insert, remove, push }) => (
                                    <div>
                                        <button type="button" className="btn btn-primary mb-3" onClick={() => push({ text: '', videoUrl: '', displayOrder: 0, representingCharacterType: 0, answers: [] })}>
                                            Soru Ekle
                                        </button>
                                        {values.questions.map((question, index) => (
                                            <div key={index} className="mb-3">
                                                <div className="mb-3">
                                                    <label htmlFor={`questions.${index}.text`} className="form-label">Soru Metni</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${touched.questions?.[index]?.text && errors.questions?.[index]?.text ? 'is-invalid' : ''}`}
                                                        name={`questions.${index}.text`}
                                                        id={`questions.${index}.text`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={question.text}
                                                    />
                                                    {touched.questions?.[index]?.text && errors.questions?.[index]?.text ? <div className="invalid-feedback">{errors.questions?.[index]?.text}</div> : null}
                                                </div>
                                                <div className='mb-3'>
                                                    <div className="mb-3">
                                                        <label htmlFor="displayOrder" className="form-label">Gösterim Sırası</label>
                                                        <label htmlFor={`questions.${index}.displayOrder`} className="form-label">Soru Metni</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${touched.questions?.[index]?.displayOrder && errors.questions?.[index]?.displayOrder ? 'is-invalid' : ''}`}
                                                        name={`questions.${index}.displayOrder`}
                                                        id={`questions.${index}.displayOrder`}
                                                        onChange={(event) => {
                                                            const value = parseInt(event.target.value, 10); // Seçilen değeri integer olarak dönüştür
                                                            setFieldValue(`questions.${index}.displayOrder`, isNaN(value) ? '' : value); // Güncellenmiş değeri setFieldValue ile ayarla
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={question.displayOrder}
                                                    />
                                                    {touched.questions?.[index]?.displayOrder && errors.questions?.[index]?.displayOrder ? <div className="invalid-feedback">{errors.questions?.[index]?.displayOrder}</div> : null}
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor={`questions.${index}.representingCharacterType`} className="form-label">Temsil Edilen Karakter Tipi</label>
                                                    <select
                                                        className="form-select"
                                                        name={`questions.${index}.representingCharacterType`}
                                                        id={`questions.${index}.representingCharacterType`}
                                                        onBlur={handleBlur}
                                                        onChange={(event) => {
                                                            const value = parseInt(event.target.value, 10); // Seçilen değeri integer olarak dönüştür
                                                            setFieldValue(`questions.${index}.representingCharacterType`, isNaN(value) ? '' : value); // Güncellenmiş değeri setFieldValue ile ayarla
                                                        }}
                                                        value={question.representingCharacterType}
                                                    >
                                                        {props.typeList?.characterTypeList?.result?.map((element, index) => {
                                                            return (
                                                                <option key={index} value={parseInt(element.enumValue, 10)}>{element.enumName}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="video">Video yükle</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name="file"
                                                        id="video"
                                                        onChange={(event) => {
                                                            const file = event.currentTarget.files[0];
                                                            setFile(file);
                                                            // Dosya yükleme işlemi başlat
                                                            // handleUpload fonksiyonunu güncelleyin veya burada dosya yükleme işlemini gerçekleştirin
                                                        }}
                                                    />
                                                    <div className="d-flex justify-content-between">
                                                        {props.fileUploadV2.spinner ?
                                                            <button class="btn btn-outline-success btn-sm float-right mt-2" type="button" disabled>
                                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                Dosya yükleniyor...
                                                            </button>
                                                            :
                                                            <button type='button' onClick={handleUpload} className='btn btn-outline-success btn-sm float-right mt-2'>
                                                                Dosyayı Yükle
                                                            </button>

                                                        }

                                                        <p className='mt-2'>{fileUrl}</p>
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor={`questions.${index}.videoUrl`} className="form-label">Video URL</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${touched.questions?.[index]?.videoUrl && errors.questions?.[index]?.videoUrl ? 'is-invalid' : ''}`}
                                                        name={`questions.${index}.videoUrl`}
                                                        id={`questions.${index}.videoUrl`}
                                                        onChange={(e) => {
                                                            setFieldValue(`questions.${index}.videoUrl`, e.target.value);
                                                        }}
                                                        onBlur={handleBlur}

                                                    />
                                                    {touched.questions?.[index]?.videoUrl && errors.questions?.[index]?.videoUrl ? <div className="invalid-feedback">{errors.questions?.[index]?.videoUrl}</div> : null}
                                                </div>
                                                <FieldArray name={`questions.${index}.answers`}>
                                                    {({ insert, remove, push }) => (
                                                        <div>
                                                            <button type="button" className="btn btn-secondary mb-3" onClick={() => push({ text: '', weight: 0, displayOrder: 0 })}>
                                                                Cevap Ekle
                                                            </button>
                                                            {question.answers.map((answer, answerIndex) => (
                                                                <div key={answerIndex} className="mb-3">
                                                                    <div className="mb-3">
                                                                        <label htmlFor={`questions.${index}.answers.${answerIndex}.text`} className="form-label">Cevap Metni</label>
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${touched.questions?.[index]?.answers?.[answerIndex]?.text && errors.questions?.[index]?.answers?.[answerIndex]?.text ? 'is-invalid' : ''}`}
                                                                            name={`questions.${index}.answers.${answerIndex}.text`}
                                                                            id={`questions.${index}.answers.${answerIndex}.text`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={answer.text}
                                                                        />
                                                                        {touched.questions?.[index]?.answers?.[answerIndex]?.text && errors.questions?.[index]?.answers?.[answerIndex]?.text ? <div className="invalid-feedback">{errors.questions?.[index]?.answers?.[answerIndex]?.text}</div> : null}
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor={`questions.${index}.answers.${answerIndex}.weight`} className="form-label">Ağırlık</label>
                                                                        <input
                                                                            type="number"
                                                                            className={`form-control ${touched.questions?.[index]?.answers?.[answerIndex]?.weight && errors.questions?.[index]?.answers?.[answerIndex]?.weight ? 'is-invalid' : ''}`}
                                                                            name={`questions.${index}.answers.${answerIndex}.weight`}
                                                                            id={`questions.${index}.answers.${answerIndex}.weight`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={answer.weight}
                                                                        />
                                                                        {touched.questions?.[index]?.answers?.[answerIndex]?.weight && errors.questions?.[index]?.answers?.[answerIndex]?.weight ? <div className="invalid-feedback">{errors.questions?.[index]?.answers?.[answerIndex]?.weight}</div> : null}
                                                                    </div>

                                                                    <button type="button" className="btn btn-danger mb-3" onClick={() => remove(answerIndex)}>
                                                                        Cevabı Sil
                                                                    </button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </FieldArray>
                                                <button type="button" className="btn btn-danger mb-3" onClick={() => remove(index)}>
                                                    Soruyu Sil
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </FieldArray>
                            <button type="submit" className="btn btn-success">Gönder</button>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateTest);