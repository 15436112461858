import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid"
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_CREATECONTENTGROUP_FULFILLED = "FETCH_CREATECONTENTGROUP_FULFILLED";
export const FETCH_CREATECONTENTGROUP_REJECTED = "FETCH_CREATECONTENTGROUP_REJECTED";
export const FETCH_CREATECONTENTGROUP_PENDING = "FETCH_CREATECONTENTGROUP_PENDING";
export const RESET_CREATECONTENTGROUP = "RESET_CREATECONTENTGROUP";

export function createContentGroup(GroupName, DisplayOrder, AddingPeerContentIdList, LeadContentId) {
    const accessToken = localStorage.getItem('accessToken');
    // let datas = {
    //     "GroupName": GroupName,
    //     "DisplayOrder": DisplayOrder,
    //     "AddingPeerContentList" : AddingPeerContentList,
    //     "LeadContentId" : LeadContentId
    // }
    // let data = JSON.stringify()
    return (dispatch) => {
        dispatch({
            type: "FETCH_CREATECONTENTGROUP",
            payload: axios({
                method: HttpMethod.POST,
                url: `${API_BASE}/api/v2/admin/create-content-group`,
                data: { GroupName, DisplayOrder, AddingPeerContentIdList, LeadContentId},
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    "Client-Id": createuuid(),
                    "ngrok-skip-browser-warning": "69920"
                },
            }).then((result) => result.data)
        });
    };
}

export function resetCreateContentGroup() {
    return (dispatch) => {
        dispatch({
            type: "RESET_CREATECONTENTGROUP",
        });
    };
}




