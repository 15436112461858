import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid"
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_PAGINGTEST_FULFILLED = "FETCH_PAGINGTEST_FULFILLED";
export const FETCH_PAGINGTEST_REJECTED = "FETCH_PAGINGTEST_REJECTED";
export const FETCH_PAGINGTEST_PENDING = "FETCH_PAGINGTEST_PENDING";
export const RESET_PAGINGTEST = "RESET_PAGINGTEST";

export function pagingTest(
    pageSize,
    pageNumber,
) {
    const accessToken = localStorage.getItem('accessToken');
    return (dispatch) => {
        dispatch({
            type: "FETCH_PAGINGTEST",
            payload: axios({
                method: HttpMethod.GET,
                url: `${API_BASE}/api/v2/admin/paging-character-test?pageNumber=${pageNumber}&pageSize=${pageSize}`,
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    "Client-Id": createuuid(),
                    "One-Id": createuuid()
                },
            }).then((result) => result.data)
        });
    };
}

export function resetPagingTest() {
    return (dispatch) => {
        dispatch({
            type: "RESET_PAGINGTEST",
        });
    };
}




