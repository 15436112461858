import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid"
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_GETCONTENTBYEMOTIONTYPE_FULFILLED = "FETCH_GETCONTENTBYEMOTIONTYPE_FULFILLED";
export const FETCH_GETCONTENTBYEMOTIONTYPE_REJECTED = "FETCH_GETCONTENTBYEMOTIONTYPE_REJECTED";
export const FETCH_GETCONTENTBYEMOTIONTYPE_PENDING = "FETCH_GETCONTENTBYEMOTIONTYPE_PENDING";
export const RESET = "RESET";

export function getContentByEmotionType() {
    const accessToken = localStorage.getItem('accessToken');
    return (dispatch) => {
        dispatch({
            type: "FETCH_GETCONTENTBYEMOTIONTYPE",
            payload: axios({
                method: HttpMethod.GET,
                url: `${API_BASE}/api/v2/admin/list-emotion-content`,
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    "Client-Id": createuuid()
                },
            }).then((result) => result.data)
        });
    };
}

export function resetGetContentByEmotionType() {
    return (dispatch) => {
        dispatch({
            type: "RESET",
        });
    };
  }




