import React, { useEffect, useState } from 'react'
import Header from '../layout/Header'
import { connect, useDispatch } from 'react-redux';
import { getContentByType } from '../../actions/content/getContentByType';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';

function Contents(props) {
    const dispatch = useDispatch();
    const [contentType, setContentType] = useState(1);
    const [emotionType, setEmotionType] = useState();
    // const emotions = {
    //     "Duygu belirtilmemiş": 0,
    //     "Öfke": 10,
    //     "Rahatsızlık": 11,
    //     "Hiddet": 12,
    //     "Beklenti": 20,
    //     "İlgi": 21,
    //     "Uyanıklık": 22,
    //     "Sıkıntı": 30,
    //     "Tiksinme": 31,
    //     "İğrenme": 32,
    //     "Kaygı": 40,
    //     "Korku": 41,
    //     "Dehşet": 42,
    //     "Coşkunluk": 50,
    //     "Mutluluk": 51,
    //     "Sükunet": 62,
    //     "Keder": 60,
    //     "Dalgınlık": 61,
    //     "Üzüntü": 62,
    //     "Şaşırma": 70,
    //     "Dikkat Dağınıklığı": 71,
    //     "Şaşırma": 72,
    //     "Kabullenme": 80,
    //     "Hayranlık": 81,
    //     "Güven": 82
    // }
    // const emotionEntries = Object.entries(emotions);



    useEffect(() => {
        dispatch(getContentByType(1))
    }, [dispatch])
    // useEffect(() => {
    //     console.log(props)
    // }, [props.getContentByType])


    return (
        <>
            <Header />
            <div className="container">
                <nav className='mt-2' aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to={`/`}>Ana sayfa</Link></li>
                        <li class="breadcrumb-item active" aria-current="page">İçerikler</li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-8 mt-3 mb-3">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h2>İçerikler</h2>
                                <small><Link to={`/add-content`} className='btn btn-outline-success'><i class="fa-light fa-plus"></i></Link></small>
                            </div>
                            <div className="card-body">
                                {props.getContentByType.spinner ?
                                    <ThreeDots
                                        height="80"
                                        width="80"
                                        radius="9"
                                        color="#4fa94d"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                    : null}
                                {props.getContentByType.done ?
                                    <ol class="list-group list-group-numbered">
                                        {props.getContentByType.getContentByType?.result?.length != 0 ?
                                            props.getContentByType.getContentByType?.result.map((element, index) => {
                                                return (
                                                    <Link
                                                        to={`/content-detail/${element.id}`}
                                                    >
                                                        <li key={index} class="list-group-item d-flex justify-content-between align-items-start mb-1">
                                                            <div class="ms-2 me-auto">
                                                                <div class="fw-bold">{element.title}</div>
                                                                {element.author}
                                                            </div>
                                                            <span class="badge bg-secondary rounded-pill">{element.culture}</span>
                                                        </li>
                                                    </Link>
                                                )
                                            })
                                            :
                                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                                <div class="ms-2 me-auto">
                                                    <div class="fw-bold">İçerik bulunamadı</div>
                                                    Henüz bir içerik bulunmamaktadır.
                                                </div>
                                            </li>
                                        }

                                    </ol>
                                    : null}
                            </div>
                            <div className="card-footer">

                            </div>
                        </div>
                    </div>
                    <div className="col-4 mt-3 mb-3">
                        <div className="card">
                            <div className="card-header">
                                <h5><i className="bi bi-journal"></i>&nbsp;İçerik tipine göre getir</h5>
                            </div>
                            <div className="card-body">
                                <ul class="list-group list-group-flush">
                                    <a
                                        onClick={() => {
                                            setContentType(1);
                                            dispatch(getContentByType(1))
                                        }}
                                        href="#"><li class={`list-group-item ${contentType == 1 ? `active` : ``}`}>Blog Makalesi</li></a>
                                    <a
                                        onClick={() => {
                                            setContentType(2);
                                            dispatch(getContentByType(2));
                                        }}
                                        href="#"><li class={`list-group-item ${contentType == 2 ? `active` : ``}`}>Aktivite</li></a>
                                    <a
                                        onClick={() => {
                                            setContentType(3);
                                            dispatch(getContentByType(3));
                                        }}
                                        href="#"><li class={`list-group-item ${contentType == 3 ? `active` : ``}`}>Bilişsel Çarpıtma</li></a>
                                    <a
                                        onClick={() => {
                                            setContentType(4);
                                            dispatch(getContentByType(4));
                                        }}
                                        href="#"><li class={`list-group-item ${contentType == 4 ? `active` : ``}`}>Duygu</li></a>
                                    <a
                                        onClick={() => {
                                            setContentType(5);
                                            dispatch(getContentByType(5))
                                        }}
                                        href="#"><li class={`list-group-item ${contentType == 5 ? `active` : ``}`}>Ders</li></a>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Contents);