import React, { useEffect, useState } from 'react'
import Header from '../layout/Header'
import { connect, useDispatch } from 'react-redux';
import { getContentByType } from '../../actions/content/getContentByType';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { getContentGroupList } from '../../actions/group/getContentGroupList';

function GroupList(props) {
    const dispatch = useDispatch();
    const [contentType, setContentType] = useState(1);
    const [emotionType, setEmotionType] = useState();



    useEffect(() => {
        dispatch(getContentGroupList(1))
    }, [dispatch])
    console.log(props.getContentGroupList)


    return (
        <>
            <Header />
            <div className="container">
            <nav className='mt-2' aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to={`/`}>Ana sayfa</Link></li>
                        <li class="breadcrumb-item active" aria-current="page">Gruplar</li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-8 mt-3 mb-3">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h2>Gruplar</h2>
                                <small><Link to={`/create-group`} className='btn btn-outline-success'><i class="fa-light fa-plus"></i></Link></small>
                            </div>
                            <div className="card-body">
                                {props.getContentGroupList.spinner ?
                                    <ThreeDots
                                        height="80"
                                        width="80"
                                        radius="9"
                                        color="#4fa94d"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                    : null}

                                {props.getContentGroupList?.done ?
                                    <ol class="list-group list-group-numbered">
                                        {props.getContentGroupList.getContentGroupList?.result?.length != 0 ?
                                            props.getContentGroupList.getContentGroupList?.result?.map((element, index) => {
                                                return (
                                                    <Link
                                                        to={`/group-detail/${element.id}`}
                                                    >
                                                        {console.log(element)}
                                                        <li key={index} class="list-group-item d-flex justify-content-between align-items-start mb-1">
                                                            <div class="ms-2 me-auto">
                                                                <div class="fw-bold">{element.groupName}</div>
                                                                {element.author}
                                                            </div>
                                                            <span class="badge bg-secondary rounded-pill">{element.culture}</span>
                                                        </li>
                                                    </Link>
                                                )
                                            })
                                            :
                                            <div class="alert alert-danger" role="alert">
                                                Henüz bir grup oluşturulmamış.
                                            </div>
                                        }

                                    </ol>
                                    : null}
                            </div>
                            <div className="card-footer">

                            </div>
                        </div>
                    </div>
                    <div className="col-4 mt-3 mb-3">
                        <div className="card">
                            <div className="card-header">
                                <h5><i className="bi bi-journal"></i>&nbsp;İçerik tipine göre getir</h5>
                            </div>
                            <div className="card-body">
                                <ul class="list-group list-group-flush">
                                    <a
                                        onClick={() => {
                                            setContentType(1);
                                            dispatch(getContentGroupList(1))
                                        }}
                                        href="#"><li class={`list-group-item ${contentType == 1 ? `active` : ``}`}>Blog Makalesi</li></a>
                                    <a
                                        onClick={() => {
                                            setContentType(2);
                                            dispatch(getContentGroupList(2));
                                        }}
                                        href="#"><li class={`list-group-item ${contentType == 2 ? `active` : ``}`}>Aktivite</li></a>
                                    <a
                                        onClick={() => {
                                            setContentType(3);
                                            dispatch(getContentGroupList(3));
                                        }}
                                        href="#"><li class={`list-group-item ${contentType == 3 ? `active` : ``}`}>Bilişsel Çarpıtma</li></a>
                                    <a
                                        onClick={() => {
                                            setContentType(4);
                                            dispatch(getContentGroupList(4));
                                        }}
                                        href="#"><li class={`list-group-item ${contentType == 4 ? `active` : ``}`}>Duygu</li></a>
                                    <a
                                        onClick={() => {
                                            setContentType(5);
                                            dispatch(getContentGroupList(5))
                                        }}
                                        href="#"><li class={`list-group-item ${contentType == 5 ? `active` : ``}`}>Ders</li></a>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupList);