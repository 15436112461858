import React, { useEffect, useState } from 'react'
import Header from '../layout/Header'
import { connect, useDispatch } from 'react-redux';
import { typeList } from '../../actions/character/typeList';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { createResult } from '../../actions/result/createResult';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router';

function ResultDetail(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Id } = useParams();
    const [representingCharacterType, setRepresentingCharacterType] = useState(null);
    const [content, setContent] = useState(null);
    const [culture, setCulture] = useState("tr");
    const [lowerBound, setLowerBound] = useState(null);
    const [upperBound, setUpperBound] = useState(null);
    useEffect(() => {
        dispatch(typeList());
    }, [dispatch])

    


    return (
        <div>
            <Header />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="card mt-4">
                            <div className="card-header">
                                <h3>Test Sonucu Ekle</h3>
                            </div>
                            <div className="card-body">
                                <div className="mb-3">
                                    <label for="characterType" className="form-label">Temsil edilen karakter tipi</label>
                                    <select
                                        onChange={(e) => setRepresentingCharacterType(parseInt(e.target.value))}
                                        className="form-select"
                                        id="characterType"
                                        aria-label="Default select example">
                                        <option>Lütfen seçiniz</option>
                                        {props.typeList.done ?
                                            props.typeList.characterTypeList.result.map((element, index) => {
                                                return (
                                                    <option value={element.enumValue}>{element.enumName}</option>
                                                )
                                            })
                                            : null}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">İçerik</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data="<p></p>"
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setContent(data);
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label for="characterType" className="form-label">Dil</label>
                                    <select
                                        onChange={(e) => setCulture(e.target.value)}
                                        className="form-select"
                                        id="characterType"
                                        aria-label="Default select example">
                                        <option>Lütfen seçiniz</option>
                                        <option value="tr">Türkçe</option>
                                        <option value="en">English</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput11" className="form-label">Alt sınır</label>
                                    <input
                                        onChange={(e) => setLowerBound(parseInt(e.target.value))}
                                        type="number" className="form-control" id="exampleFormControlInput11" placeholder="Alt sınır" />
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput12" className="form-label">Üst sınır</label>
                                    <input
                                        onChange={(e) => setUpperBound(parseInt(e.target.value))}
                                        type="number" className="form-control" id="exampleFormControlInput12" placeholder="Üst sınır" />
                                </div>
                                <div className="float-end">
                                    <button
                                        // onClick={handleSubmit}
                                        className="btn btn-success">Kaydet</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(ResultDetail);