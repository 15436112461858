import {
    /** FETCH PROFESSIONS  */
    FETCH_GETLECTUREDETAIL_FULFILLED,
    FETCH_GETLECTUREDETAIL_REJECTED,
    FETCH_GETLECTUREDETAIL_PENDING,
    RESET_GETLECTUREDETAIL
} from "../../actions/lecture/getLectureDetail";
const initialState = {
    done: false,
    error: false,
    spinner: false
};

export default (state = initialState, action) => {

    switch (action.type) {
        case FETCH_GETLECTUREDETAIL_PENDING:
            return {
                done: false,
                error: false,
                spinner: true,
            };
        case FETCH_GETLECTUREDETAIL_FULFILLED:
            return {
                ...state,
                getLectureDetail: action.payload,
                done: true,
                error: false,
                spinner: false,
                isLogin: true
            };
        case FETCH_GETLECTUREDETAIL_REJECTED:
            return {
                ...state,
                error: action.payload,
                done: false,
                spinner: false,
                isLogin: false
            };
        case RESET_GETLECTUREDETAIL:
            return {
                initialState,
                done: false,
                error: false,
                spinner: false
            };
        default:
            return state;
    }
};