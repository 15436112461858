import {
    /** FETCH PROFESSIONS  */
    FETCH_PAGINGTEST_FULFILLED,
    FETCH_PAGINGTEST_REJECTED,
    FETCH_PAGINGTEST_PENDING,
    RESET_PAGINGTEST
} from "../../actions/character/pagingTest";
const initialState = {
    done: false,
    error: false,
    spinner: false
};

export default (state = initialState, action) => {

    switch (action.type) {
        case FETCH_PAGINGTEST_PENDING:
            return {
                done: false,
                error: false,
                spinner: true,
            };
        case FETCH_PAGINGTEST_FULFILLED:
            return {
                ...state,
                pagingTest: action.payload,
                done: true,
                error: false,
                spinner: false,
                isLogin: true
            };
        case FETCH_PAGINGTEST_REJECTED:
            return {
                ...state,
                error: action.payload,
                done: false,
                spinner: false,
                isLogin: false
            };
        case RESET_PAGINGTEST:
            return {
                initialState,
                done: false,
                error: false,
                spinner: false
            };
        default:
            return state;
    }
};