import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid";
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_UPDATECONTENTGROUP_FULFILLED =
  "FETCH_UPDATECONTENTGROUP_FULFILLED";
export const FETCH_UPDATECONTENTGROUP_REJECTED =
  "FETCH_UPDATECONTENTGROUP_REJECTED";
export const FETCH_UPDATECONTENTGROUP_PENDING =
  "FETCH_UPDATECONTENTGROUP_PENDING";
export const RESET_UPDATECONTENTGROUP = "RESET_UPDATECONTENTGROUP";

export function updateContentGroup(
  ContentGroupId,
  GroupName,
  DisplayOrder,
  AddingPeerContentIdList,
  RemovingPeerContentIdList,
  LeadContentId
) {
  const accessToken = localStorage.getItem("accessToken");
  return (dispatch) => {
    dispatch({
      type: "FETCH_UPDATECONTENTGROUP",
      payload: axios({
        method: HttpMethod.POST,
        url: `${API_BASE}/api/v2/admin/update-content-group`,
        data: {
          ContentGroupId,
          GroupName,
          DisplayOrder,
          AddingPeerContentIdList,
          RemovingPeerContentIdList,
          LeadContentId,
        },
        headers: {
          Authorization: "Bearer " + accessToken,
          "Accept-Language": "tr-tr",
          "Content-Type": "application/json",
          "Client-Id": createuuid(),
          "ngrok-skip-browser-warning": "69920",
        },
      }).then((result) => result.data),
    });
  };
}

export function resetUpdateContentGroup() {
  return (dispatch) => {
    dispatch({
      type: "RESET_UPDATECONTENTGROUP",
    });
  };
}
