import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { logOut } from '../../actions/auth/login';


function Header(props) {


    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : false

    if (user === false) {
        navigate("/login")
    }

    function handleLogoutClick() {
        dispatch(logOut())
    };
    if (props.login.isLogin === false) {
        navigate("/login")
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light ps-4 pe-4">
            <div className="container-fluid">
                <Link to={`/`} className="navbar-brand mt-2 mb-2"> <img src="https://www.mentaskop.com/wp-content/uploads/2023/06/mentaskop.png" width="200" alt="" /><b className='ms-2 me-3'></b> </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className={`${location.pathname === "/" ? `nav-link active` : `nav-link`}`} aria-current="page" to="/"><i className="fa-light fa-square-poll-vertical me-2"></i>Ana sayfa</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={`/contents`} className={`${location.pathname === "/contents" || location.pathname === "/add-content" || location.pathname === "/content-detail/"  ? `nav-link active` : `nav-link`}`} href=""><i className="fa-thin fa-file-lines me-2"></i>İçerik Yönetimi</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`${location.pathname === "/group" ? `nav-link active` : `nav-link`}`} to={`/group`}><i className="fa-thin fa-layer-group me-2"></i>Grup Yönetimi</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`${location.pathname === "/lectures" ? `nav-link active` : `nav-link`}`} to={`/lectures`}><i className="fa-light fa-presentation-screen me-2"></i>Ders Yönetimi</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`${location.pathname === "/tests" ? `nav-link active` : `nav-link`}`} to={`/tests`}><i className="fa-light fa-presentation-screen me-2"></i>Karakter Testleri</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`${location.pathname === "/list-test-result" ? `nav-link active` : `nav-link`}`} to={`/list-test-result`}><i className="fa-light fa-presentation-screen me-2"></i>Test Sonuç Şablonları</Link>
                        </li>
                    </ul>
                    {/* test */}
                    <div className="d-flex">
                        <div className="dropdown dropstart">
                            <button className="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa-light fa-user"></i>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><p className='ms-4 mt-2 text-danger'>{user.name}</p></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" href="#">Profil</a></li>
                                <li><a className="dropdown-item" href="#">Ayarlar</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" onClick={handleLogoutClick} href="#">Çıkış yap</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);