class LocalManager {
    static instance2 = new LocalManager();

    constructor() {
        return LocalManager.instance2;
    }

    static get instance() {
        return LocalManager.instance2;
    }

    getItem(key) {
        return localStorage.getItem(key);
    }

    setItem(key, value) {
        localStorage.setItem(key, value);
    }

    removeItem(key) {
        localStorage.removeItem(key);
    }

    clear() {
        localStorage.clear();
    }
}


export default LocalManager;
