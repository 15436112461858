import React, { useEffect, useState } from 'react'
import Header from '../layout/Header'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { createContent, resetCreateContent } from '../../actions/content/createContent';
import Swal from 'sweetalert2';
function AddContent(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [author, setAuthor] = useState("");
    const [culture, setCulture] = useState("tr");
    const [displayOrder, setDisplayOrder] = useState(0);
    const [imageUrl, setImageUrl] = useState("");
    const [contentText, setContentText] = useState("");
    const [contentType, setContentType] = useState(1);
    const [mounted, setMounted] = useState(true);



    function handleSubmit() {
        dispatch(createContent(imageUrl, subTitle, title, author, displayOrder, contentText, contentType, culture));
    }


    useEffect(() => {
        if (mounted && props.createContent.done) {
            if (props.createContent.createContent?.hasError) {
                Swal.fire({
                    icon: 'error',
                    title: 'İşlem Başarısız',
                    text: props.createContent.createContent?.responseCode,
                    confirmButtonText: "Anladım",
                });
                return
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'İşlem Başarılı',
                    text: "İçerik başarıyla oluşturuldu.",
                    confirmButtonText: "Anladım",
                });
                dispatch(resetCreateContent());
            }

            setMounted(false);
            navigate("/contents");

        }
    }, [mounted, props.createContent])

    return (
        <div>
            <Header />
            <div className="container">
                <nav className='mt-2' aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to={`/`}>Ana sayfa</Link></li>
                        <li class="breadcrumb-item"><Link to={`/contents`}>İçerikler</Link></li>
                        <li class="breadcrumb-item active" aria-current="page">İçerik ekle</li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col">
                        <div class="form-floating mb-3 mt-3">
                            <input
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                                type="text"
                                class="form-control"
                                id="title" placeholder="Metin başlığı" />
                            <label for="title">Metin başlığı</label>
                        </div>
                        <div class="form-floating mb-3 mt-3">
                            <input
                                onChange={(e) => {
                                    setSubTitle(e.target.value);
                                }}
                                type="text" class="form-control" id="subtitle" placeholder="Metin alt başlığı" />
                            <label for="subtitle">Metin alt başlığı</label>
                        </div>
                        <div class="form-floating mb-3 mt-3">
                            <input
                                onChange={(e) => {
                                    setAuthor(e.target.value);
                                }}
                                type="text" class="form-control" id="author" placeholder="Yazar" />
                            <label for="author">Yazar</label>
                        </div>
                        <div class="form-floating">
                            <select
                                onChange={(e) => {
                                    setCulture(e.target.value);

                                }}
                                class="form-select" id="culture" >
                                <option value="tr">Türkçe</option>
                                <option value="en">English</option>
                            </select>
                            <label for="culture">Lütfen dil seçiniz</label>
                        </div>
                        <div class="form-floating mb-3 mt-3">
                            <input
                                onChange={(e) => {
                                    setDisplayOrder(parseInt(e.target.value));
                                }}
                                type="number" class="form-control" id="displayorder" placeholder="Örn: 12" />
                            <label for="displayorder">Sıralama</label>
                        </div>
                        <div class="form-floating mb-3 mt-3">
                            <input
                                onChange={(e) => {
                                    setImageUrl(e.target.value);
                                }}
                                type="text" class="form-control" id="image" placeholder="Url" />
                            <label for="image">İçerik görsel linki</label>
                        </div>
                        <div class="form-floating mb-3">
                            <select
                                onChange={(e) => {
                                    setContentType(parseInt(e.target.value));
                                }}
                                class="form-select" id="contenttype">
                                <option value={1}>Blog Makalesi</option>
                                <option value={2}>Aktivite</option>
                                <option value={3}>Bilişsel Çarpıtma</option>
                                <option value={4}>Duygu</option>
                                <option value={5}>Ders</option>
                            </select>
                            <label for="contenttype">İçerik türünü seçiniz</label>
                        </div>
                        <CKEditor
                            editor={ClassicEditor}
                            data="<p></p>"
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setContentText(data);
                            }}
                        />
                        <div className='d-flex justify-content-end'>
                            {props.createContent.spinner ?
                                <button class="btn btn-outline-success mt-2" type="button" disabled>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    İşlem devam ediyor...
                                </button>
                                :
                                <button onClick={handleSubmit} className='btn btn-outline-success mt-2 '>İçerik ekle</button>

                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(AddContent);