import {
    /** FETCH PROFESSIONS  */
    FETCH_SUBSCRIBEUSER_FULFILLED,
    FETCH_SUBSCRIBEUSER_REJECTED,
    FETCH_SUBSCRIBEUSER_PENDING,
    RESET_SUBSCRIBEUSER
} from "../../actions/user/subscribeUser";
const initialState = {
    done: false,
    error: false,
    spinner: false
};

export default (state = initialState, action) => {

    switch (action.type) {
        case FETCH_SUBSCRIBEUSER_PENDING:
            return {
                done: false,
                error: false,
                spinner: true,
            };
        case FETCH_SUBSCRIBEUSER_FULFILLED:
            return {
                ...state,
                subscribeUser: action.payload,
                done: true,
                error: false,
                spinner: false,
                isLogin: true
            };
        case FETCH_SUBSCRIBEUSER_REJECTED:
            return {
                ...state,
                error: action.payload,
                done: false,
                spinner: false,
                isLogin: false
            };
        case RESET_SUBSCRIBEUSER:
            return {
                initialState,
                done: false,
                error: false,
                spinner: false
            };
        default:
            return state;
    }
};