import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid"
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_UPDATETESTANSWER_FULFILLED = "FETCH_UPDATETESTANSWER_FULFILLED";
export const FETCH_UPDATETESTANSWER_REJECTED = "FETCH_UPDATETESTANSWER_REJECTED";
export const FETCH_UPDATETESTANSWER_PENDING = "FETCH_UPDATETESTANSWER_PENDING";
export const RESET_UPDATETESTANSWER = "RESET_UPDATETESTANSWER";


export function updateTestAnswer(
    answerId,
    text,
    weight,
    displayOrder,
    isDeleted,
) {
    const accessToken = localStorage.getItem('accessToken');
    return (dispatch) => {
        dispatch({
            type: "FETCH_UPDATETESTANSWER",
            payload: axios({
                method: HttpMethod.POST,
                url: `${API_BASE}/api/v2/admin/update-character-test-answer`,
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    "Client-Id": createuuid(),
                    "One-Id": createuuid()
                },
                data: {
                    answerId,
                    text,
                    weight,
                    displayOrder,
                    isDeleted,
                }
            }).then((result) => result.data)
        });
    };
}

export function resetUpdateTestAnswer() {
    return (dispatch) => {
        dispatch({
            type: "RESET_UPDATETESTANSWER",
        });
    };
}




