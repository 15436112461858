import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid";
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_UPDATELECTURE_FULFILLED = "FETCH_UPDATELECTURE_FULFILLED";
export const FETCH_UPDATELECTURE_REJECTED = "FETCH_UPDATELECTURE_REJECTED";
export const FETCH_UPDATELECTURE_PENDING = "FETCH_UPDATELECTURE_PENDING";
export const RESET_UPDATELECTURE = "RESET_UPDATELECTURE";

export function updateLecture(
  LectureId,
  DisplayOrder,
  Title,
  SubTitle,
  AddingSections,
  RemovingSections,
  ContentIdList
) {
  const accessToken = localStorage.getItem("accessToken");
  return (dispatch) => {
    dispatch({
      type: "FETCH_UPDATELECTURE",
      payload: axios({
        method: HttpMethod.POST,
        url: `${API_BASE}/api/v2/admin/update-lecture`,
        data: {
            LectureId,
            DisplayOrder,
            Title,
            SubTitle,
            AddingSections,
            RemovingSections,
            ContentIdList
        },
        headers: {
          Authorization: "Bearer " + accessToken,
          "Accept-Language": "tr-tr",
          "Content-Type": "application/json",
          "Client-Id": createuuid(),
          "ngrok-skip-browser-warning": "69920"
        },
      }).then((result) => result.data),
    });
  };
}

export function resetUpdateLecture() {
  return (dispatch) => {
    dispatch({
      type: "RESET_UPDATELECTURE",
    });
  };
}
