import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid"
import HttpMethod from "../../helpers/HttpMethod";
import LocalManager from "../../helpers/LocalManager";
import LocalKeys from "../../helpers/LocalKeys";
export const FETCH_LOGIN_FULFILLED = "FETCH_LOGIN_FULFILLED";
export const FETCH_LOGIN_REJECTED = "FETCH_LOGIN_REJECTED";
export const FETCH_LOGIN_PENDING = "FETCH_LOGIN_PENDING";
export const LOGOUT = "LOGOUT";

export function login(phoneNumber, password) {
    LocalManager.instance.setItem(LocalKeys.phoneNumber, phoneNumber);
    
    // localStorage.setItem("phoneNumber", phoneNumber)
    return (dispatch) => {
        dispatch({
            type: "FETCH_LOGIN",
            payload: axios({
                method: HttpMethod.POST,
                url: `${API_BASE}/api/v2/auth/login`,
                data: { phoneNumber, password },
                headers: {
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    "Client-Id": createuuid(),
                    "One-Id" : createuuid()
                },
            }).then((result) => result.data)
        });
    };
}

export function logOut() {
    sessionStorage.clear();
    localStorage.clear();

    return (dispatch) => {
        dispatch({
            type: "LOGOUT",
        });
    };
}
