import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import 'react-phone-input-2/lib/style.css'
import { login } from '../../actions/auth/login';



function Login(props) {
    const [mounted, setMounted] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");

    console.log(phoneNumber)


    function handleSubmit(event) {
        event.preventDefault();
        dispatch(login("+"+phoneNumber, password))
    }

    useEffect(()=>{
        if (props.login.isLogin === true) {
            navigate("/");
        }
    },[props.login])

    
    useEffect(() => {
        if (mounted && props.login.done === false) {
            if (Object.keys(props.login.error).length !== 0) {
                if (props.login.error.response.data) {
                    Swal.fire({
                        confirmButtonText: 'Anladım',
                        icon: 'error',
                        title: 'Oops...',
                        text: props.login.error.response.data.responseMessage,
                    })
                } else {
                    Swal.fire({
                        confirmButtonText: 'anladım',
                        icon: 'error',
                        title: 'Oops...',
                        text: "Bir şeyler ters gitti.",
                    })
                }

            }
        }
        setMounted(true);
    }, [props.login])


    return (
        <div className='mt-5'>
            <div className='row d-flex justify-content-center'>
                <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 ms-2 me-2">
                    <h1 className='text-primary text-start mt-4'>Mentaskop Admin Paneli</h1>
                    <form onSubmit={handleSubmit} className="input-group">
                        <label htmlFor="basic-url" className="form-label mt-8">Telefon Numarası</label>
                        <div className="input-group mb-3">
                            <PhoneInput
                                required
                                id="phoneNumber"
                                type="text"
                                placeholder="Telefon numaranız"
                                name="phoneNumber"
                                country={"tr"}
                                inputStyle={{ width: `100% !important!` }}
                                enableSearch={false}
                                value={phoneNumber}
                                onChange={(e) => { setPhoneNumber(e) }}
                            />                                </div>
                        <label htmlFor="password" className="form-label ">Şifre</label>
                        <div className="input-group mb-3">
                            <input
                                type="password"
                                placeholder="Şifre"
                                className="form-control"
                                onChange={(e) => { setPassword(e.target.value) }}
                                value={password}
                                id="password"
                                autoComplete='off'
                                aria-describedby="basic-addon3" />
                        </div>
                    
                        <div className='d-grid gap-2 w-100 mt-4'>
                            {props.login.spinner ?
                                <button type='submit' className='btn btn-primary' disabled>
                                    <span className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true'></span>
                                    Giriş Yapılıyor
                                </button>
                                :
                                <button type='submit' className='btn btn-primary'>Giriş Yap</button>
                            }
                        </div>

                       

                    </form>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);