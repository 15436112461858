import React, { useEffect, useState } from 'react'
import Header from '../layout/Header'
import { connect, useDispatch } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { typeList } from '../../actions/character/typeList';
import { pagingTest } from '../../actions/character/pagingTest'

function Tests(props) {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(pagingTest(10, 0))
    }, [dispatch])

    console.log(props.typeList)
    return (
        <div>
            <Header />
            <div className="container">
                <nav className='mt-2' aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to={`/`}>Ana sayfa</Link></li>
                        <li class="breadcrumb-item active" aria-current="page">İçerikler</li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-12 mt-3 mb-3">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h2>Karakter Testleri</h2>
                                <div>
                                    {/* <small><Link to={`/create-test`} className='btn btn-outline-success me-3'>Test sonucu</Link></small> */}
                                    <small><Link to={`/create-test`} className='btn btn-outline-success'><i class="fa-light fa-plus"></i></Link></small>
                                </div>
                            </div>
                            <div className="card-body">
                                {props.pagingTest.spinner ?
                                    <ThreeDots
                                        height="80"
                                        width="80"
                                        radius="9"
                                        color="#4fa94d"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                    : null}
                                {props.pagingTest.done ?
                                    <ol class="list-group list-group-numbered">
                                        {props.pagingTest.pagingTest?.result?.resultList?.length != 0 ?
                                            props.pagingTest.pagingTest?.result?.resultList?.map((element, index) => {
                                                return (
                                                    <Link
                                                        key={index}
                                                        to={`/test-detail/${element.id}`}
                                                    >
                                                        <li class="list-group-item d-flex justify-content-between align-items-start mb-1">
                                                            <div class="ms-2 me-auto">
                                                                <div class="fw-bold">{element.title}</div>
                                                                {element.author}
                                                            </div>
                                                            <span class="badge bg-secondary rounded-pill">{element.culture}</span>
                                                        </li>
                                                    </Link>
                                                )
                                            })
                                            :
                                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                                <div class="ms-2 me-auto">
                                                    <div class="fw-bold">İçerik bulunamadı</div>
                                                    Henüz bir içerik bulunmamaktadır.
                                                </div>
                                            </li>
                                        }

                                    </ol>
                                    : null}
                                {console.log(props.pagingTest)}
                            </div>
                            <div className="card-footer">

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Tests);