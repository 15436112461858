import { API_BASE } from "../../config/env";
import axios from "axios";
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_CREATEIMAGE_FULFILLED = "FETCH_CREATEIMAGE_FULFILLED";
export const FETCH_CREATEIMAGE_REJECTED = "FETCH_CREATEIMAGE_REJECTED";
export const FETCH_CREATEIMAGE_PENDING = "FETCH_CREATEIMAGE_PENDING";
export const RESET = "RESET";

export function createImage(prompts) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_CREATEIMAGE",
      payload: axios({
        method: HttpMethod.POST,
        url: `https://api.openai.com/v1/images/generations`,
        data: {
          prompt: `There should be no text, text or sentence in the image you produce an image according to the area I give in double quotes. "${prompts}"`,
          n: 1,
          size: "1024x1024",
          model: "dall-e-3",
        },
        headers: {
          Authorization:
            "Bearer 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",
          "Content-Type": "application/json",
        },
      }).then((result) => result.data),
    });
  };
}

export function resetCreateImage() {
  return (dispatch) => {
    dispatch({
      type: "RESET",
    });
  };
}
