import { API_BASE } from "../../config/env";
import axios from "axios";
import HttpMethod from "../../helpers/HttpMethod";
import { createuuid } from "../../helpers/CreateUuid";
export const FETCH_UPLOADFILEV2_FULFILLED = "FETCH_UPLOADFILEV2_FULFILLED";
export const FETCH_UPLOADFILEV2_REJECTED = "FETCH_UPLOADFILEV2_REJECTED";
export const FETCH_UPLOADFILEV2_PENDING = "FETCH_UPLOADFILEV2_PENDING";
export const RESET_UPLOADFILEV2 = "RESET_UPLOADFILEV2";

export function fileUploadv2(file) {
  const formData = new FormData();

  // console.log(file)
  // Append the file and prompts to the form data
  formData.append("file", file);
  const accessToken = localStorage.getItem('accessToken');

  return (dispatch) => {
    dispatch({
      type: "FETCH_UPLOADFILEV2",
      payload: axios({
        method: HttpMethod.POST,
        url: `${API_BASE}/api/v2/admin/upload-file`,
        data: formData,
        headers: {
            "Authorization": "Bearer " + accessToken,
            "Accept-Language": "tr-tr",
            "Content-Type": "multipart/form-data",
            "Client-Id": createuuid(),
            "One-Id" : createuuid(),
            "ngrok-skip-browser-warning": "69920"
        },
      }).then((result) => result.data),
    });
  };
}

export function resetUploadFile2() {
  return (dispatch) => {
    dispatch({
      type: "RESET_UPLOADFILEV2",
    });
  };
}
