import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid"
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_UPDATECONTENT_FULFILLED = "FETCH_UPDATECONTENT_FULFILLED";
export const FETCH_UPDATECONTENT_REJECTED = "FETCH_UPDATECONTENT_REJECTED";
export const FETCH_UPDATECONTENT_PENDING = "FETCH_UPDATECONTENT_PENDING";
export const RESET_UPDATECONTENT = "RESET_UPDATECONTENT";

export function updateContent(Id, ImageUrl, SubTitle, Title, Author, DisplayOrder, ContentText, IsDeleted) {
    const accessToken = localStorage.getItem('accessToken');
    return (dispatch) => {
        dispatch({
            type: "FETCH_UPDATECONTENT",
            payload: axios({
                method: HttpMethod.POST,
                url: `${API_BASE}/api/v2/admin/update-content`,
                data: {Id, ImageUrl, SubTitle, Title, Author, DisplayOrder, ContentText, IsDeleted},
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    "Client-Id": createuuid()
                },
            }).then((result) => result.data)
        });
    };
}

export function resetUpdateContent() {
    return (dispatch) => {
        dispatch({
            type: "RESET_UPDATECONTENT",
        });
    };
}




