import React from 'react'
import Header from '../layout/Header'

function UpdateQuestion() {
    return (
        <div>
            <Header />
            <h1>UpdateQuestion</h1>
        </div>
    )
}

export default UpdateQuestion
