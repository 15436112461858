import {
    /** FETCH PROFESSIONS  */
    FETCH_UPDATETEST_FULFILLED,
    FETCH_UPDATETEST_REJECTED,
    FETCH_UPDATETEST_PENDING,
    RESET_UPDATETEST
} from "../../actions/character/updateTest";
const initialState = {
    done: false,
    error: false,
    spinner: false
};

export default (state = initialState, action) => {

    switch (action.type) {
        case FETCH_UPDATETEST_PENDING:
            return {
                done: false,
                error: false,
                spinner: true,
            };
        case FETCH_UPDATETEST_FULFILLED:
            return {
                ...state,
                updateTest: action.payload,
                done: true,
                error: false,
                spinner: false,
            };
        case FETCH_UPDATETEST_REJECTED:
            return {
                ...state,
                error: action.payload,
                done: false,
                spinner: false,
            };
        case RESET_UPDATETEST:
            return {
                initialState,
                done: false,
                error: false,
                spinner: false
            };
        default:
            return state;
    }
};