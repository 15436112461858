import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid"
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_GETTEST_FULFILLED = "FETCH_GETTEST_FULFILLED";
export const FETCH_GETTEST_REJECTED = "FETCH_GETTEST_REJECTED";
export const FETCH_GETTEST_PENDING = "FETCH_GETTEST_PENDING";
export const RESET_GETTEST = "RESET_GETTEST";

export function getTest(Id) {
    const accessToken = localStorage.getItem('accessToken');
    return (dispatch) => {
        dispatch({
            type: "FETCH_GETTEST",
            payload: axios({
                method: HttpMethod.GET,
                url: `${API_BASE}/api/v2/admin/character-test-detail?characterTestId=${Id}`,
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    "Client-Id": createuuid()
                },
            }).then((result) => result.data)
        });
    };
}

export function resetGetTest() {
    return (dispatch) => {
        dispatch({
            type: "RESET_GETTEST",
        });
    };
}




