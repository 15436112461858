import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid"
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_CREATETESTQUESTION_FULFILLED = "FETCH_CREATETESTQUESTION_FULFILLED";
export const FETCH_CREATETESTQUESTION_REJECTED = "FETCH_CREATETESTQUESTION_REJECTED";
export const FETCH_CREATETESTQUESTION_PENDING = "FETCH_CREATETESTQUESTION_PENDING";
export const RESET_CREATETESTQUESTION = "RESET_CREATETESTQUESTION";


export function createTestQuestion(
    characterTestId,
    text,
    videoUrl,
    displayOrder,
    representingCharacterType,
    answers
) {
    const accessToken = localStorage.getItem('accessToken');
    return (dispatch) => {
        dispatch({
            type: "FETCH_CREATETESTQUESTION",
            payload: axios({
                method: HttpMethod.POST,
                url: `${API_BASE}/api/v2/admin/create-character-test-question`,
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    "Client-Id": createuuid(),
                    "One-Id": createuuid()
                },
                data: {
                    characterTestId,
                    text,
                    videoUrl,
                    displayOrder,
                    representingCharacterType,
                    answers
                }
            }).then((result) => result.data)
        });
    };
}

export function resetCREATETESTQUESTION() {
    return (dispatch) => {
        dispatch({
            type: "RESET_CREATETESTQUESTION",
        });
    };
}




