import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid"
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_DELETEGROUP_FULFILLED = "FETCH_DELETEGROUP_FULFILLED";
export const FETCH_DELETEGROUP_REJECTED = "FETCH_DELETEGROUP_REJECTED";
export const FETCH_DELETEGROUP_PENDING = "FETCH_DELETEGROUP_PENDING";
export const RESET_DELETEGROUP = "RESET_DELETEGROUP";

export function deleteGroup(id) {
    const accessToken = localStorage.getItem('accessToken');

    return (dispatch) => {
        dispatch({
            type: "FETCH_DELETEGROUP",
            payload: axios({
                method: HttpMethod.GET,
                url: `${API_BASE}/api/v2/admin/delete-content-group?id=${id}`,
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    "Client-Id": createuuid(),
                    "ngrok-skip-browser-warning": "69920"
                },
            }).then((result) => result.data)
        });
    };
}

export function resetDeleteGroup() {
    return (dispatch) => {
        dispatch({
            type: "RESET_DELETEGROUP",
        });
    };
}




