import React from 'react'
import Header from '../layout/Header'

function TypeList() {
    return (
        <div>
            <Header />
            <h1>TypeList</h1>
        </div>
    )
}

export default TypeList
