import { API_BASE } from "../../config/env";
import axios from "axios";
import { createuuid } from "../../helpers/CreateUuid"
import HttpMethod from "../../helpers/HttpMethod";
export const FETCH_GETCONTENTGROUPLIST_FULFILLED = "FETCH_GETCONTENTGROUPLIST_FULFILLED";
export const FETCH_GETCONTENTGROUPLIST_REJECTED = "FETCH_GETCONTENTGROUPLIST_REJECTED";
export const FETCH_GETCONTENTGROUPLIST_PENDING = "FETCH_GETCONTENTGROUPLIST_PENDING";
export const RESET = "RESET";

export function getContentGroupList(contentType) {
    const accessToken = localStorage.getItem('accessToken');
    return (dispatch) => {
        dispatch({
            type: "FETCH_GETCONTENTGROUPLIST",
            payload: axios({
                method: HttpMethod.GET,
                url: `${API_BASE}/api/v2/admin/list-content-group?contentType=${contentType}`,
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    "Client-Id": createuuid(),
                    "ngrok-skip-browser-warning": "69920"
                },
            }).then((result) => result.data)
        });
    };
}

export function resetGetContentGroupList() {
    return (dispatch) => {
        dispatch({
            type: "RESET",
        });
    };
  }




