import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Header from '../layout/Header';
import { getTest } from '../../actions/character/getTest';
import { Formik, Form, Field, FieldArray } from 'formik';
import { createTestQuestion } from '../../actions/character/createTestQuestion';
import { fileUploadv2 } from '../../actions/fileupload/fileUploadv2';
import { resetUpdateTestQuestion, updateTestQuestion } from '../../actions/character/updateTestQuestion';
import { resetUpdateTest, updateTest } from '../../actions/character/updateTest';
import Swal from 'sweetalert2';
import { resetUpdateTestAnswer, updateTestAnswer } from '../../actions/character/updateTestAnswer';

function TestDetail(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Id } = useParams();

    const [id, setId] = useState(null);
    const [title, setTitle] = useState('');
    const [displayOrder, setDisplayOrder] = useState(null);
    const [isOnline, setIsOnline] = useState(false);
    const [fileUrl, setFileUrl] = useState("");
    const [culture, setCulture] = useState("tr");

    const [questionId, setQuestionId] = useState(null);
    const [questionText, setQuestionText] = useState('');
    const [questionVideoUrl, setQuestionVideoUrl] = useState('');
    const [questionDisplayOrder, setQuestionDisplayOrder] = useState(null);
    const [questionCharacterType, setQuestionCharacterType] = useState(null);

    const [answerId, setAnswerId] = useState(null);
    const [answerText, setAnswerText] = useState('');
    const [answerWeight, setAnswerWeight] = useState(null);
    const [answerDisplayOrder, setAnswerDisplayOrder] = useState(null);

    const characterTypes = [
        "Neuroticism",
        "Agreeableness",
        "Openness",
        "Conscientiousness",
        "Extraversion"
    ]

    const initialValues = {
        questions: [
            {
                characterTestId: Id,
                text: '',
                videoUrl: '',
                displayOrder: 0,
                representingCharacterType: '',
                answers: [
                    {
                        text: '',
                        weight: 0,
                        displayOrder: 0
                    }
                ]
            }
        ]
    };

    const onSubmit = values => {
        dispatch(createTestQuestion(values.questions[0].characterTestId, values.questions[0].text, values.questions[0].videoUrl, values.questions[0].displayOrder, values.questions[0].representingCharacterType, values.questions[0].answers))
    };

    useEffect(() => {
        dispatch(getTest(Id))
    }, [dispatch])

    useEffect(() => {

        if (props.fileUploadV2.done) {
            setFileUrl(props.fileUploadV2.fileUploadv2.result)
        }

    }, [props.fileUploadV2])

    function handleDelete(id) {
        dispatch(updateTestQuestion(id, "", "", null, true, null))
    }



    function handleTestUpdate() {
        dispatch(updateTest(id, title, culture, true, false, displayOrder))
    }

    useEffect(() => {
        if (props.updateTest.done) {
            Swal.fire({
                icon: 'success',
                title: 'Başarılı',
                text: 'Test başarıyla silindi.'
            }).then(() => {
                dispatch(resetUpdateTest());
                navigate('/tests')
            })

        }
    }, [props.updateTest])

    useEffect(() => {

        if (props.updateTestQuestion.done) {
            dispatch(resetUpdateTestQuestion());
            Swal.fire({
                icon: 'success',
                title: 'Başarılı',
                text: 'Soru başarıyla güncellendi.'
            }).then(() => {
                dispatch(getTest(Id))
            })
        }


    }, [props.updateTestQuestion])
    useEffect(() => {

        if (props.updateTestAnswer.done) {
            dispatch(resetUpdateTestAnswer());
            Swal.fire({
                icon: 'success',
                title: 'Başarılı',
                text: 'Soru başarıyla güncellendi.'
            }).then(() => {
                dispatch(getTest(Id))
            })
        }


    }, [props.updateTestAnswer])

    useEffect(()=>{

        if(props.getTest.done){
            setId(props.getTest.getTest.result.id)
            setTitle(props.getTest.getTest.result.title)
            setDisplayOrder(props.getTest.getTest.result.displayOrder)
            setIsOnline(props.getTest.getTest.result.isOnline)
            setCulture(props.getTest.getTest.result.culture)
        }


        
    },[props.getTest])


    return (
        <div>
            <Header />
            <div className="container mt-4">
                {props.getTest.spinner ?
                    <div className="text-center">
                        <div className="spinner-border text-success" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    : null}
                {props.getTest.done ?
                    <div className="row">
                        <div className="col-4">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between">
                                    <h5>Test detay</h5>
                                    <button
                                        onClick={() => {

                                            handleTestUpdate()
                                        }}
                                        className='btn btn-outline-success btn-sm'>Güncelle</button>
                                    <button
                                        onClick={() => {
                                            dispatch(updateTest(Id, title, culture, isOnline, true, displayOrder))
                                        }}
                                        className='btn btn-outline-danger btn-sm'>Testi sil</button>
                                </div>
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Test Başlığı</label>
                                        <input
                                            value={title}
                                            type="text"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder="Başlık giriniz"
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                        <div id="emailHelp" className="form-text">Oluşturulan sınavın test başlığı.</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput2" className="form-label">Test Kültürü</label>
                                        <select
                                            defaultValue={props.getTest?.getTest?.result?.culture}
                                            onChange={(e) => setCulture(e.target.value)}
                                            className='form-select'
                                            name=""
                                            id="exampleFormControlInput2">
                                            <option value="tr">Türkçe</option>
                                            <option value="en">İngilizce</option>
                                        </select>

                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput2" className="form-label">Test Sıralaması</label>
                                        <input
                                            value={displayOrder}
                                            type="text"
                                            className="form-control"
                                            id="exampleFormControlInput2"
                                            placeholder="Başlık giriniz"
                                            onChange={(e) => setDisplayOrder(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                Online
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between">
                                    <h2>Test Soruları</h2>
                                    <button className='btn btn-outline-success btn-sm' data-bs-toggle="modal" data-bs-target="#exampleModal">Soru ekle</button>
                                </div>
                                <div className="card-body">
                                    {props.getTest.getTest.result.questions.map((element, index) => {
                                        return (
                                            <div key={index} className="card">
                                                <div className='d-flex justify-content-end'>
                                                    <button
                                                        onClick={() => {
                                                            setQuestionId(element.id)
                                                            setQuestionText(element.text)
                                                            setQuestionVideoUrl(element.videoUrl)
                                                            setQuestionDisplayOrder(element.displayOrder)
                                                            setQuestionCharacterType(element.representingCharacterType)

                                                        }}
                                                        data-bs-toggle="modal"
                                                        data-bs-target={`#questionUpdateModal${index}`}
                                                        className='btn btn-outline-secondary me-2 btn-sm mt-2 mb-2'>Soruyu güncelle</button>
                                                    <button
                                                        onClick={() => handleDelete(element.id)}
                                                        className='btn btn-outline-danger btn-sm mt-2 mb-2'>Soruyu sil</button>
                                                </div>
                                                {/**Soru güncelleme modal'ı  */}
                                                <div className="modal fade" id={`questionUpdateModal${index}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">Soruyu güncelle</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="mb-3">
                                                                    <label htmlFor="text1" className="form-label">Soru metni</label>
                                                                    <textarea
                                                                        className='form-control mt-2'
                                                                        id="text1"
                                                                        onChange={(e) => setQuestionText(e.target.value)}
                                                                        value={questionText}
                                                                        cols="30"
                                                                        rows="10"></textarea>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="videourl" className="form-label">Video Url</label>
                                                                    <input
                                                                        type="text"
                                                                        onChange={(e) => setQuestionVideoUrl(e.target.value)}
                                                                        className="form-control"
                                                                        id="videourl"
                                                                        value={questionVideoUrl} />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="order" className="form-label">Sıralama</label>
                                                                    <input
                                                                        type="text"
                                                                        onChange={(e) => setQuestionDisplayOrder(parseInt(e.target.value))}
                                                                        className="form-control"
                                                                        id="order"
                                                                        value={questionDisplayOrder} />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="characterType" className="form-label">Karakter tipi</label>
                                                                    <select
                                                                        onChange={(e) => setQuestionCharacterType(parseInt(e.target.value))}
                                                                        className='form-select'
                                                                        defaultValue={element.representingCharacterType}
                                                                        name=""
                                                                        id="characterType">
                                                                        <option value="0">Neuroticism</option>
                                                                        <option value="1">Agreeableness</option>
                                                                        <option value="2">Openness</option>
                                                                        <option value="3">Conscientiousness</option>
                                                                        <option value="4">Extraversion</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    id='closeUpdateQuestionModal'
                                                                    type="button" className="btn btn-secondary" data-bs-dismiss="modal">Vazgeç</button>
                                                                <button
                                                                    onClick={() => {
                                                                        dispatch(updateTestQuestion(element.id, questionText, questionVideoUrl, questionDisplayOrder, false, questionCharacterType))
                                                                    }}
                                                                    data-bs-dismiss="modal"
                                                                    className="btn btn-primary">Değişiklikleri kaydet</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ratio ratio-16x9">
                                                    <video src={element.videoUrl} controls>
                                                        <source src={element.videoUrl} type='video/mp4' />
                                                    </video>
                                                </div>
                                                <div className="card-body">
                                                    {/* <h5 className="card-title">{element.text}</h5> */}
                                                    <p className="card-text"><b>Karakter tipi :</b> {characterTypes[element.representingCharacterType]}</p>
                                                    <p className="card-text"> <b>Soru içeriği :</b> {element.text}</p>
                                                    <div className="table table-hover">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">İçerik</th>
                                                                    <th scope="col">Ağırlık</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {element.answers.map((element, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td>{element.text}</td>
                                                                            <td>{element.weight}</td>
                                                                            <td>
                                                                                <button
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target={`#answerUpdateModal${index}`}
                                                                                    className='btn btn-secondary btn-sm'
                                                                                    onClick={() => {
                                                                                        setAnswerId(element.id)
                                                                                        setAnswerText(element.text)
                                                                                        setAnswerWeight(element.weight)
                                                                                        setAnswerDisplayOrder(element.displayOrder)
                                                                                    }}
                                                                                >
                                                                                    Güncelle
                                                                                </button>
                                                                            </td>
                                                                            
                                                                            {/**Cevap güncelleme modal'ı  */}
                                                                            <div className="modal fade" id={`answerUpdateModal${index}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                                <div className="modal-dialog">
                                                                                    <div className="modal-content">
                                                                                        <div className="modal-header">
                                                                                            <h5 className="modal-title" id="exampleModalLabel">Cevabı güncelle</h5>
                                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                        </div>
                                                                                        <div className="modal-body">
                                                                                            <div className="mb-3">
                                                                                                <label htmlFor="text1" className="form-label">Cevap metni</label>
                                                                                                <textarea
                                                                                                    className='form-control mt-2'
                                                                                                    id=""
                                                                                                    onChange={(e) => setAnswerText(e.target.value)}
                                                                                                    value={answerText}
                                                                                                    cols="30"
                                                                                                    rows="10"></textarea>
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <label htmlFor="videourl" className="form-label">Cevap ağırlığı</label>
                                                                                                <input
                                                                                                    onChange={(e) => setAnswerWeight(parseInt(e.target.value))}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    id="videourl"
                                                                                                    value={answerWeight}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <label htmlFor="videourl" className="form-label">Cevap sıralama</label>
                                                                                                <input
                                                                                                    onChange={(e) => setAnswerDisplayOrder(parseInt(e.target.value))}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    id="videourl"
                                                                                                    value={answerDisplayOrder} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="modal-footer">
                                                                                            <button
                                                                                                id='closeUpdateAnswerModal'
                                                                                                type="button" className="btn btn-secondary" data-bs-dismiss="modal">Vazgeç</button>

                                                                                            <button
                                                                                                onClick={() => {
                                                                                                    dispatch(updateTestAnswer(answerId, answerText, answerWeight, answerDisplayOrder, false))
                                                                                                }}
                                                                                                data-bs-dismiss="modal"
                                                                                                className="btn btn-primary">Değişiklikleri kaydet</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </tr>
                                                                    )

                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                        </div>
                    </div>
                    : null}
            </div>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Teste soru ekle</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                                {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                    <form onSubmit={handleSubmit}>
                                        <FieldArray name="questions">
                                            {({ insert, remove, push }) => (
                                                values.questions.map((question, index) => (
                                                    <div key={index} className="mb-3">
                                                        <Field name={`questions[${index}].characterTestId`} value={Id} type="hidden" />
                                                        <div className="mb-3">
                                                            <label htmlFor={`questions[${index}].text`} className="form-label">Soru Başlığı</label>
                                                            <Field
                                                                as="input"
                                                                type="text"
                                                                className="form-control"
                                                                name={`questions[${index}].text`}
                                                                placeholder="Başlık giriniz"
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor={`questions[${index}].fileUrl`} className="form-label">Soru Video</label>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                onChange={(event) => {
                                                                    dispatch(fileUploadv2(event.target.files[0]))
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor={`questions[${index}].videoUrl`} className="form-label">Soru Video URL</label>
                                                            <Field
                                                                as="input"
                                                                type="text"
                                                                className="form-control"
                                                                name={`questions[${index}].videoUrl`}
                                                                placeholder="Video URL giriniz"
                                                                value={fileUrl}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor={`questions[${index}].displayOrder`} className="form-label">Soru Sıralaması</label>
                                                            <Field
                                                                as="input"
                                                                type="number"
                                                                className="form-control"
                                                                name={`questions[${index}].displayOrder`}
                                                                placeholder="Sıralama numarası giriniz"
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor={`questions[${index}].representingCharacterType`} className="form-label">Karakter Tipi</label>
                                                            <Field name={`questions[${index}].representingCharacterType`} as="select" className="form-select"
                                                                onChange={(event) => {
                                                                    const value = parseInt(event.target.value, 10);
                                                                    setFieldValue(`questions[${index}].representingCharacterType`, isNaN(value) ? '' : value);
                                                                }}
                                                            >
                                                                <option value="">Seçiniz</option>
                                                                <option value="0">Neuroticism</option>
                                                                <option value="1">Agreeableness</option>
                                                                <option value="2">Openness</option>
                                                                <option value="3">Conscientiousness</option>
                                                                <option value="4">Extraversion</option>
                                                            </Field>
                                                        </div>

                                                        <FieldArray name={`questions[${index}].answers`}>
                                                            {({ insert, remove, push }) => (
                                                                <div>
                                                                    {question.answers.map((answer, answerIndex) => (
                                                                        <div key={answerIndex} className="mb-3">
                                                                            <div className="mb-3">
                                                                                <label htmlFor={`questions[${index}].answers[${answerIndex}].text`} className="form-label">Cevap Metni</label>
                                                                                <Field
                                                                                    as="input"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name={`questions[${index}].answers[${answerIndex}].text`}
                                                                                    placeholder="Cevap giriniz"
                                                                                />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label htmlFor={`questions[${index}].answers[${answerIndex}].weight`} className="form-label">Ağırlık</label>
                                                                                <Field
                                                                                    as="input"
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    name={`questions[${index}].answers[${answerIndex}].weight`}
                                                                                />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label htmlFor={`questions[${index}].answers[${answerIndex}].displayOrder`} className="form-label">Gösterim Sırası</label>
                                                                                <Field
                                                                                    as="input"
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    name={`questions[${index}].answers[${answerIndex}].displayOrder`}
                                                                                />
                                                                            </div>
                                                                            <button type="button" className="btn btn-danger mb-3" onClick={() => remove(answerIndex)}>Cevabı Sil</button>
                                                                        </div>
                                                                    ))}
                                                                    <button type="button" className="btn btn-primary" onClick={() => push({ text: '', weight: 0, displayOrder: 0 })}>Cevap Ekle</button>
                                                                </div>
                                                            )}
                                                        </FieldArray>
                                                    </div>
                                                ))
                                            )}
                                        </FieldArray>
                                        <button type="submit" className="btn btn-success float-right">Soruyu ekle</button>
                                    </form>
                                )}
                            </Formik>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Vazgeç</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(TestDetail);